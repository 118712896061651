import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './SignUpPage.css';

//FIREBASE
import { signInWithRedirect, isSignInWithEmailLink, signInWithEmailLink, sendSignInLinkToEmail } from 'firebase/auth';
import { getRedirectResult } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';

//FUNCTIONS
import { AuthContext } from '../AuthContext';

//IMPORT UI
import ProcessingPage from './ProcessingPage';

//IMAGES
import googlesignin from '../assets/images/googlesignblue.png';


const SignInPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true');
  const [emailValidationMsg, setEmailValidationMsg] = useState('');



  useEffect(() => {
    // console.log("Current user: ", currentUser);
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

      useEffect(() => {
        // Check for redirect result
        getRedirectResult(auth)
          .then((result) => {
          localStorage.setItem('isLoading', 'false');
          setIsLoading(false);
            if (result.user) {
              navigate('/dashboard');
            }
          })
          .catch((error) => {
            localStorage.setItem('isLoading', 'false');
            setIsLoading(false);
            // console.error("Error getting redirect result:", error);
          });
      }, [navigate]);

      useEffect(() => {

        const checkForEmailLinkSignIn = () => {
          if (isSignInWithEmailLink(auth, window.location.href)) {
            let emailForSignIn = window.localStorage.getItem('emailForSignIn');
            if (!emailForSignIn) {
              emailForSignIn = window.prompt('Please provide your email for confirmation');
            }
            signInWithEmailLink(auth, emailForSignIn, window.location.href)
              .then(() => {
                window.localStorage.removeItem('emailForSignIn');
                navigate('/dashboard');
              })
              .catch((error) => {
                // console.error('Error completing sign-in with email link:', error);
              });
          }
        };
        checkForEmailLinkSignIn();
      }, [navigate]);



  const actionCodeSettings = {
    url: window.location.origin + '/signin',
    handleCodeInApp: true,
  };


  const [buttonEmailText, setButtonEmailText] = useState('Send Sign-In Link');

  const handleEmailSignInLink = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailValidationMsg('Please enter a valid email address.');
      return;
    }
    setEmailValidationMsg('');
    setButtonEmailText('Sending Email...'); // Update button text to indicate email is being sent
  
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      alert('Check your email for the sign-in link.'); // Consider using a more sophisticated notification method
      setButtonEmailText('Send Sign-In Link'); // Reset button text after email is sent
    } catch (error) {
      // console.error('Error sending sign-in link:', error);
      setButtonEmailText('Send Sign-In Link'); // Reset button text on error
    }
  };
  




  const handleGoogleSignIn = async () => {
    try {
      localStorage.setItem('isLoading', 'true');
      await signInWithRedirect(auth, googleProvider);
    } catch (error) {
      localStorage.setItem('isLoading', 'false');
      // console.error("Error signing in with Google:", error);
    }
  };

  

  return (
    <div className="signup-page">
      <Helmet>
        <title>Bumpups</title>
        <meta name="description" content="Get started with Bumpups and enjoy 60 minutes of free YouTube timestamp generation. No credit card required." />
      </Helmet>
      {isLoading ? (
        <ProcessingPage />
      ) : (
        <div className="signup-form">
          <h1>Log in with your account</h1>
          <p className="special-text">Get back to your dashboard and continue enhancing viewer engagement on your videos.</p>
            <>
            <input 
                type="email" 
                placeholder="Enter email address" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                className="email-input" 
              />
              {emailValidationMsg && <p className="forgot-password-msg">{emailValidationMsg}</p>}
              <button className="continue-button" onClick={handleEmailSignInLink}>{buttonEmailText}</button>
            </>

          <p className="login-text">Don't have an account? <strong><Link to="/signup">Sign up</Link></strong></p>

          <div className="button-divider">
            <span className="divider-text">OR</span>
          </div>
          
          <button className="google-button" onClick={handleGoogleSignIn}>
            <img src={googlesignin} alt="Google Logo" className="google-logo" />
          </button>

          <p className="terms-text">By continuing, you agree to Bumpups's <a href="/terms-of-service">Terms of Service</a>. Read our <a href="/privacy-policy">Privacy Policy</a>.</p>
        </div>
      )}
    </div>
  );
};

export default SignInPage;
