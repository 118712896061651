import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

//FIREBASE
import { signInWithRedirect } from 'firebase/auth';

//FUNCTIONS
import { AuthContext } from '../AuthContext';
import { auth, googleProvider } from '../firebase';
import { AuthProvider } from '../AuthContext';

//IMPORT UI
import Footer from './Footer';
import CookieBar from '../components/CookieBar';
import SellingPoints from '../components/miscPages/SellingPoints';

//IMAGES
import thumbnailHome from '../assets/images/bumpupThumbnail.jpeg';
import signupGoogle from '../assets/images/signup-goog.png';
import starIcon from '../assets/images/star-v1.png';
import cbYT from '../assets/images/corbinbrown-yt.jpg';
import AquinasYT from '../assets/images/Aquinas-YT.jpg';
import FIGGMUNITYT from '../assets/images/FIGGMUNITY-YT.jpg';
import RottenMango from '../assets/images/RottenMango.jpg';
import ComeAndTalk from '../assets/images/ComeAndTalk.jpg';
import Valuetainment from '../assets/images/Valuetainment.jpg';
import keyMommentsImage from '../assets/images/keypointsDesign.jpg';
import utubeLogo from '../assets/images/v2-creator.png';
import utubeLogoon from '../assets/images/v2-youtubeon.png';



const profiles = [
    { img: AquinasYT, name: "Pints With Aquinas", subscribers: "546K" },
    { img: cbYT, name: "Corbin Brown", subscribers: "44K" },
    { img: FIGGMUNITYT, name: "FIGGMUNITY WORLD", subscribers: "148K" },
    { img: RottenMango, name: "Rotten Mango", subscribers: "2.8M" },
    { img: ComeAndTalk, name: "Come And Talk 2 Me", subscribers: "592K" },
    { img: Valuetainment, name: "Valuetainment", subscribers: "5.6M" }
];

const videoTags = [
    "SaquonBarkley", "PhiladelphiaEagles", "NFL", "Football", "RunningBacks",
    "CollegeFootball", "PennState", "NFLDraft", "ProfessionalFootball",
    "Teamwork", "AthleticAchievements", "PlayerSafety"
];

const keyTakeaways = [
    {
        icon: '🏈',
        text: "Saquon Barkley's Transition: Reflects on joining the Philadelphia Eagles, embracing a new role, and contributing to team success"
    },
    {
        icon: '🏆',
        text: "NFL and College Reflections: Discusses college football challenges, professional transitions, and the evolving landscape of the sport"
    },
    {
        icon: '🏃‍♂️',
        text: "Running Back Insights: Explores the running back market, player versatility, and the physical demands of professional football"
    }
];

const timestamps = [
    { time: "14:32", description: " Meeting the team" },
    { time: "19:32", description: " College football advice" },
    { time: "26:30", description: " College camaraderie" },
    { time: "29:06", description: " Records and legacies" },
    { time: "45:10", description: " Rookie pranks" },
    { time: "47:19", description: " First NFL touchdown" },
    { time: "49:38", description: " Favorite rivalry moment" },
    { time: "53:59", description: " New York success" },
    { time: "56:30", description: " Major League Baseball season" },
    { time: "1:03:55", description: " Running back market" },
    { time: "1:06:28", description: " Changing the narrative" },
    { time: "1:22:59", description: " Drinking Contest Plans" },
    { time: "1:25:24", description: " Banning Hip Drop Tackles" }
];

const videoDescriptions = [
    "Join hosts Travis and Jason Kelce on New Heights as they welcome NFL star Saquon Barkley to discuss his transition to the Eagles, playing with Brandon Graham, and his excitement for the team's potential.",
    "Saquon Barkley shares his journey to the Eagles with the Kelce brothers. Hear about his new role, the warm reception in Philly, and his optimism for the team's future in this engaging episode of New Heights.",
    "On New Heights, Saquon Barkley talks with Travis and Jason Kelce about his move to the Eagles, his new teammates, and the support he's received. Enjoy a lively chat filled with insights and personal stories."
];





function HomePage() {
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);
    const [faqOpen, setFaqOpen] = useState(null);
    const [tagIndices, setTagIndices] = useState([0, 1]);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [currentTakeawayIndex, setCurrentTakeawayIndex] = useState(0);
    const [currentTimestampIndex, setCurrentTimestampIndex] = useState(0);
    const [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(0);

    const toggleFaq = (index) => {
        setFaqOpen(faqOpen === index ? null : index);
    };

    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newIndices = new Set();

            // Ensure we have 2 unique indices for tags
            while (newIndices.size < 2) {
                const newIndex = Math.floor(Math.random() * videoTags.length);
                newIndices.add(newIndex);
            }

            const indicesArray = Array.from(newIndices);

            // Update states
            setTagIndices(indicesArray);
            setHighlightedIndex(Math.floor(Math.random() * 2));
            setCurrentTakeawayIndex((prevIndex) => (prevIndex + 1) % keyTakeaways.length);
            setCurrentTimestampIndex((prevIndex) => (prevIndex + 1) % timestamps.length);
            setCurrentDescriptionIndex((prevIndex) => (prevIndex + 1) % videoDescriptions.length);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const displayedTags = tagIndices.map(index => videoTags[index]);

    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
    };

    const handleSignUp = () => {
        navigate('/signup');
    };

    const handleGoogleSignUp = async () => {
        try {
            localStorage.setItem('isLoading', 'true');
            await signInWithRedirect(auth, googleProvider);
            navigate('/dashboard');
        } catch (error) {
            localStorage.setItem('isLoading', 'false');
            // console.error("Error signing up with Google", error);;
        }
    };

    return (
        <AuthProvider>
            <div className="hp-homepage-container">
                <div className="hp-header">
                    <h1 className="hp-main-header">
                        Today is a great day to<br />
                        make content <span className="highlight">easy</span>
                    </h1>
                    <h2 className="hp-sub-header">
                        upload your video & let's <span className="enhanced">bump it</span>
                    </h2>
                </div>
                
                <div className="hp-wrapper">
                    <div className="hp-image-section">
                        <img src={thumbnailHome} alt="Thumbnail" className="hp-homepage-image"/>
                    </div>
                    <div className="hp-info-box hp-video-tags">
                        <p className="hp-video-tags-title">Video Tags</p>
                        <div className="video-tags-content hp-fade-in-out">
                            {displayedTags.map((tag, index) => (
                                <div key={index} className={`video-tag ${index === highlightedIndex ? 'highlighted' : ''}`}>
                                    {index === highlightedIndex && <img src={starIcon} alt="Star" className="star-icon" />}
                                    {tag}
                                </div>
                            ))}
                            <div className="video-tag">
                                + more
                            </div>
                        </div>
                    </div>
                    <div className="hp-info-box hp-key-takeaways">
                        <p className="hp-key-takeaway-title">Key Takeaways</p>
                        <div className="key-takeaway-content hp-fade-in-out">
                            <span className="key-takeaway-icon">{keyTakeaways[currentTakeawayIndex].icon}</span>
                            <span className="key-takeaway-text">{truncateText(keyTakeaways[currentTakeawayIndex].text, 80)}</span>
                        </div>
                    </div>
                    <div className="hp-info-box hp-description">
                        <p className="hp-description-title">Video Description</p>
                        <div className="hp-description-wrapper hp-fade-in-out">
                            <p className="hp-description-content">{truncateText(videoDescriptions[currentDescriptionIndex], 110)}</p>
                        </div>
                    </div>
                    <div className="hp-info-box hp-timestamps">
                        <p className="hp-timestamps-title">Timestamps</p>
                        <div className="hp-timestamps-content">
                            <div key={currentTimestampIndex} className="hp-timestamp-item hp-fade-in-out">
                                <span className="hp-timestamp-time">{timestamps[currentTimestampIndex].time}</span> -{" "}
                                <span className="hp-timestamp-description">{timestamps[currentTimestampIndex].description}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hp-cta-section">
                    <p className="hp-cta-text">Transform your video content with just <b>one click</b> - we'll handle tags, descriptions, key takeaways, and timestamps, making it easy to engage and grow your audience.</p>
                    <div className="hp-cta-buttons">
                        <button className="hp-cta-button email-button" onClick={handleSignUp}>
                            Start free with email
                        </button>
                        <button className="hp-cta-button hp-google-button" onClick={handleGoogleSignUp}>
                            <img src={signupGoogle} alt="Sign up with Google" className="hp-google-image" />
                        </button>
                    </div>
                    <div className="hp-cta-footer-wrapper">
                        <div className="hp-cta-footer">
                            <p>✔ <b>Free forever</b> for core features</p>
                            <p>✔ <b>YouTube</b> integration made easy</p>
                        </div>
                    </div>
                </div>

                <div className="hp-scrolling-bar-container-wrapper">
                    <div className="hp-scrolling-bar-container">
                        <div className="hp-scrolling-bar">
                            {Array.from({ length: 20 }).flatMap((_, index) =>
                                profiles.map((profile, profileIndex) => (
                                    <div key={`${index}-${profileIndex}`} className="hp-profile">
                                        <div className="hp-profile-img-wrapper">
                                            <img src={profile.img} alt="Profile" className="hp-profile-img"/>
                                            <img src={utubeLogo} alt="YouTube Logo" className="hp-youtube-logo"/>
                                        </div>
                                        <p className="hp-profile-name">{profile.name}</p>
                                        <p className="hp-profile-subscribers">{profile.subscribers}</p>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <div className="valueExamples">
                    <div className="valueExamples-text">
                        <div className="youtube-logo-container">
                            <img src={utubeLogoon} alt="YouTube Logo" className="youtube-logohomepage" />
                            <h2 className="seamless-integration-title">Made For Creators</h2>
                        </div>
                    </div>
                    <div className="valueExamples-image">
                        <img src={keyMommentsImage} alt="Key Moments Design" />
                    </div>
                </div>
                <SellingPoints />
                <div className="faq-section">
                    <h1>Got questions?</h1>
                    <div className="faq-item" onClick={() => toggleFaq(6)}>
                        <h3>Is Bumpups free to use?</h3>
                        <div className={faqOpen === 6 ? "faq-content show" : "faq-content"}>
                            Yes, Bumpups offers 60 minutes of free processing, allowing you to experience the full range of our features, including tags, descriptions, key takeaways, and timestamps.
                        </div>
                    </div>
                    <div className="faq-item" onClick={() => toggleFaq(0)}>
                        <h3>How does the AI generate timestamps?</h3>
                        <div className={faqOpen === 0 ? "faq-content show" : "faq-content"}>
                            Our advanced AI algorithms analyze your video content to identify key chapters and moments, enabling viewers to navigate your content more efficiently.
                        </div>
                    </div>
                    <div className="faq-item" onClick={() => toggleFaq(2)}>
                        <h3>Is there a limit to the number of videos I can process?</h3>
                        <div className={faqOpen === 2 ? "faq-content show" : "faq-content"}>
                            We offer processing for the first 60 minutes of video content at no cost. Beyond that, charges will apply based on the total duration of the videos. Our pricing plans are designed to be flexible and affordable, catering to creators of all sizes.
                        </div>
                    </div>
                    <div className="faq-item" onClick={() => toggleFaq(3)}>
                        <h3>What video durations can the platform support?</h3>
                        <div className={faqOpen === 3 ? "faq-content show" : "faq-content"}>
                            Our platform supports videos ranging from <b>4 minutes to 3.5 hours</b> in length. We are actively working on extending this range to accommodate longer videos.
                        </div>
                    </div>
                </div>
                <CookieBar />
                <Footer />
            </div>
        </AuthProvider>
    );
}

export default HomePage;
