import { useState, useEffect } from 'react';

const useResponsiveDesign = () => {
  const [device, setDevice] = useState(getDeviceType());

  function getDeviceType() {
    const width = window.innerWidth;
    if (width <= 768) return 'mobile';
    if (width <= 1280) return 'tablet';
    return 'desktop';
  }

  useEffect(() => {
    const handleResize = () => {
      setDevice(getDeviceType());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return device;
};

export default useResponsiveDesign;
