import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './SignUpPage.css';

//FIREBASE
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithRedirect } from 'firebase/auth';
import { getRedirectResult } from 'firebase/auth';

//FUNCTIONS
import { auth, googleProvider } from '../firebase';
import { AuthContext } from '../AuthContext';

//IMPORT UI
import ProcessingPage from './ProcessingPage';

//IMAGES
import googlesignin from '../assets/images/googlesignblue.png';



const SignUpPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true');
  const [emailValidationMsg, setEmailValidationMsg] = useState('');


      useEffect(() => {
        // console.log("Current user: ", currentUser);
        if (currentUser) {
          navigate('/dashboard');
        }
      }, [currentUser, navigate]);


  useEffect(() => {
    // console.log('Checking for sign-in with email link...');

    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.user) {
          navigate('/dashboard');
        }
        localStorage.setItem('isLoading', 'false');
        setIsLoading(false);

      } catch (error) {
        // console.error("Error handling redirect result:", error);
        localStorage.setItem('isLoading', 'false');
        setIsLoading(false);

      }
    };

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let emailForSignIn = window.localStorage.getItem('emailForSignIn');
      if (!emailForSignIn) {
        emailForSignIn = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, emailForSignIn, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          navigate('/dashboard');
        })
        .catch((error) => {
          // console.error('Error completing sign-in with email link:', error);
        });
    } else {
      handleRedirectResult();
    }
  }, [navigate]);



  //EMAIL SIGN UP
  //EMAIL SIGN UP
  //EMAIL SIGN UP

  const actionCodeSettings = {
    url: window.location.origin + '/signup',
    handleCodeInApp: true,
  };


const [buttonSignUpText, setButtonSignUpText] = useState('Continue');

const handleEmailSignUp = async () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(email)) {
    setEmailValidationMsg('Please enter a valid email address.');
    return;
  }
  setEmailValidationMsg('');
  setButtonSignUpText('Sending Email...'); // Indicate that the email is being sent

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    alert('Check your email for the sign-in link.'); // Or use a more sophisticated method to notify the user
    setButtonSignUpText('Continue'); // Reset the button text after email is sent
  } catch (error) {
    // console.error('Error sending sign-in link:', error);
    setButtonSignUpText('Continue'); // Reset the button text on error
  }
};


  //GOOGLE SIGN UP
  //GOOGLE SIGN UP
  //GOOGLE SIGN UP


  const handleGoogleSignUp = async () => {
    try {
      localStorage.setItem('isLoading', 'true');
        await signInWithRedirect(auth, googleProvider);
        navigate('/dashboard');
    } catch (error) {
      localStorage.setItem('isLoading', 'false');
        // console.error("Error signing up with Google", error);
    }
};


  return (
    <div className="signup-page">
      <Helmet>
        <title>Bumpups</title>
        <meta name="description" content="Get started with Bumpups and enjoy 60 minutes of free YouTube timestamp generation. No credit card required." />
      </Helmet>
      {isLoading ? (
        <ProcessingPage />
      ) : (
        <div className="signup-form">
          <h1>Start Your Account For Free</h1>
          <p className="special-text">Get started with <b>60 minutes free</b>. No credit card, no hassle—just more engagement for your videos.</p>

          <input 
                type="email" 
                placeholder="Enter email address" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                className="email-input" 
              />
          {emailValidationMsg && <p className="email-validation-msg">{emailValidationMsg}</p>}

          <button className="continue-button" onClick={handleEmailSignUp}>{buttonSignUpText}</button>

          <p className="login-text">Already have an account? <strong><Link to="/signin">Log in</Link></strong></p>

          <div className="button-divider">
            <span className="divider-text">OR</span>
          </div>

          <button className="google-button" onClick={handleGoogleSignUp}>
            <img src={googlesignin} alt="Google Logo" className="google-logo" />
          </button>

          <p className="terms-text">By continuing, you agree to Bumpups's <a href="/terms-of-service">Terms of Service</a>. Read our <a href="/privacy-policy">Privacy Policy</a>.</p>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
