import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './listviewBumpUp.css';
import useResponsiveDesign from '../../components/useResponsiveDesign'; // Adjust the path as necessary

// FIREBASE
import { collection, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';

// IMPORT UI
import ProcessingPage from '../../unAuthPages/ProcessingPage';

// IMAGES
import noAccessImageActive from '../../assets/images/thumbnailPlacehlder-active.jpg';
import utubeLogoConnected from '../../assets/images/redYouTube-v1.png';
import youtubeWhite from '../../assets/images/v2-creator.png';
import bumpupsLogo from '../../assets/images/bumpup-small.png';
import viewVideoValue from '../../assets/images/v2-settings.png';

const ListViewBumpUp = ({ accessToken }) => {
  const [videos, setVideos] = useState([]);
  const [isContentAvailable, setIsContentAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);  // State for pagination
  const videosPerPage = 15;  // Videos per page
  const navigate = useNavigate();
  const device = useResponsiveDesign(); // Use the custom hook

  
  const checkFirestore = useCallback((userId) => {
    const userVideosRef = collection(db, "users", userId, "bumpContent");

    // console.log("Setting up Firestore snapshot listener...");
    return onSnapshot(userVideosRef, (snapshot) => {
      if (!snapshot.empty) {
        const videosFromFirestore = [];
        snapshot.forEach((doc) => {
          const videoData = doc.data();
          videoData.id = doc.id.replace('bump-', '');  // Remove the 'bump-' prefix
          videosFromFirestore.push(videoData);
        });

        // Sort videos by video_upload_time in descending order
        videosFromFirestore.sort((a, b) => b.video_upload_time.seconds - a.video_upload_time.seconds);

        const localStorageKey = `videoCache_${userId}_BumpedVideos`;

        // console.log("Videos found in Firestore snapshot");
        setVideos(videosFromFirestore);

        // Update local storage with new data
        localStorage.setItem(localStorageKey, JSON.stringify(videosFromFirestore));
        setIsContentAvailable(true);
      } else {
        // console.log("No videos found in Firestore snapshot");
        setVideos([]);
        const localStorageKey = `videoCache_${userId}_BumpedVideos`;
        localStorage.removeItem(localStorageKey);
        setIsContentAvailable(false);
      }
      setIsLoading(false);
    }, (error) => {
      // console.error("Error fetching data from Firestore:", error);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const checkDataAvailability = async () => {
      setIsLoading(true);
      const user = auth.currentUser;

      if (user) {
        const localStorageKey = `videoCache_${user.uid}_BumpedVideos`;
        const cachedVideos = localStorage.getItem(localStorageKey);

        if (cachedVideos) {
          // console.log("Videos found in local storage");
          setVideos(JSON.parse(cachedVideos));
          setIsContentAvailable(true);
          setIsLoading(false);
        } else {
          // console.log("No videos found in local storage, checking Firestore...");
          checkFirestore(user.uid);
        }
      }
    };

    checkDataAvailability();
  }, [checkFirestore]);

  const handleVideoClick = (videoId) => {
    // console.log("Video ID:", videoId); // Console log to debug the issue
    navigate(`/bumper/${videoId}`);
  };

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "";
    const date = new Date(timestamp.seconds * 1000);
    const formattedDate = date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate() + ', ' + date.getFullYear();
    return formattedDate;
  };

  const formatDuration = (seconds) => {
    if (!seconds) return "";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return hours > 0
      ? `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
      : `${minutes}:${secs.toString().padStart(2, '0')}`;
  };

  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  // Pagination logic
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);
  const totalPages = Math.ceil(videos.length / videosPerPage);
  const startVideoNumber = indexOfFirstVideo + 1;
  const endVideoNumber = Math.min(indexOfLastVideo, videos.length);

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };


  // const [bumpingTimeLeft, setBumpingTimeLeft] = useState({});
  // const [isTranscriptReady, setIsTranscriptReady] = useState(false);


//   useEffect(() => {
//     videos.forEach((video) => {
//         const videoId = video.id;
//         const videoDuration = video.video_duration_in_seconds;
//         let videoUploadTime = video.video_upload_time;
//         setIsTranscriptReady(video.is_transcript_ready);

//         // Print the values for debugging
//         console.log('videoDuration:', videoDuration);
//         console.log('videoUploadTime:', videoUploadTime);

//         // Manually convert the Firestore Timestamp to Date if necessary
//         if (videoUploadTime && videoUploadTime.seconds !== undefined && videoUploadTime.nanoseconds !== undefined) {
//             videoUploadTime = new Date(videoUploadTime.seconds * 1000 + videoUploadTime.nanoseconds / 1000000);
//         } else if (videoUploadTime && typeof videoUploadTime.toDate === 'function') {
//             videoUploadTime = videoUploadTime.toDate();
//         } else {
//             console.log('videoUploadTime is not a valid Firestore Timestamp or Date object');
//             setBumpingTimeLeft(prevState => ({
//                 ...prevState,
//                 [videoId]: 'Upload time not available',
//             }));
//             return;
//         }

//         const calculateBumpingTimeLeft = () => {
//             if (!videoUploadTime) {
//                 console.log('videoUploadTime is null or undefined');
//                 setBumpingTimeLeft(prevState => ({
//                     ...prevState,
//                     [videoId]: 'Upload time not available',
//                 }));
//                 return;
//             }

//             // Check if values are already in localStorage
//             let totalProcessingTimeMinutes = localStorage.getItem(`${videoId}_processingTimeMinutes`);
//             let uploadTimeInMillis = localStorage.getItem(`${videoId}_uploadTimeInMillis`);

//             // If not, calculate and store them
//             if (!totalProcessingTimeMinutes || !uploadTimeInMillis) {
//                 totalProcessingTimeMinutes = (videoDuration / 3600) * 10; // SET HOW LONG IT TAKES FOR AN PROCESS HOUR -- right now its 10 Min
//                 uploadTimeInMillis = videoUploadTime.getTime(); // Get time in milliseconds directly

//                 // Store the values in localStorage only if videoUploadTime is valid
//                 localStorage.setItem(`${videoId}_processingTimeMinutes`, totalProcessingTimeMinutes);
//                 localStorage.setItem(`${videoId}_uploadTimeInMillis`, uploadTimeInMillis);
//             }

//             const currentTimeInMillis = Date.now();
//             const elapsedTimeMinutes = (currentTimeInMillis - uploadTimeInMillis) / (1000 * 60); // Elapsed time in minutes

//             const remainingTimeMinutes = totalProcessingTimeMinutes - elapsedTimeMinutes;

//             if (remainingTimeMinutes > 60) {
//                 setBumpingTimeLeft(prevState => ({
//                     ...prevState,
//                     [videoId]: `${Math.ceil(remainingTimeMinutes / 60)} hours left`,
//                 }));
//             } else if (remainingTimeMinutes > 1) {
//                 setBumpingTimeLeft(prevState => ({
//                     ...prevState,
//                     [videoId]: `${Math.ceil(remainingTimeMinutes)} minutes left`,
//                 }));
//             } else if (remainingTimeMinutes > 0) {
//                 setBumpingTimeLeft(prevState => ({
//                     ...prevState,
//                     [videoId]: `${Math.ceil(remainingTimeMinutes * 60)} seconds left`,
//                 }));
//             } else {
//                 setBumpingTimeLeft(prevState => ({
//                     ...prevState,
//                     [videoId]: 'successfully bumped',
//                 }));
//             }
//         };

//         calculateBumpingTimeLeft();
//         const interval = setInterval(calculateBumpingTimeLeft, 60000); // Update every minute

//         return () => clearInterval(interval);
//     });
// }, [videos]);








  return (
    <div className="listbump-video-container">
        <div className="listbump-header-row">
          <div className="listbump-header-item">Videos</div>
          {(device === 'tablet' || device === 'desktop') && (
            <>
              <div className="listbump-header-item">Duration</div>
              <div className="listbump-header-item">Distribution</div>
              <div className="listbump-header-item">Quality</div>
              {device === 'desktop' && (
                <div className="listbump-header-item">Bumped</div>
              )}
            </>
          )}
        </div>
      {isLoading ? (
        <div><ProcessingPage /></div>
      ) : isContentAvailable ? (
        <>
          {currentVideos.map((video, index) => (
            <div
              key={index}
              className="listbump-video-row"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="listbump-video-item">
                <img 
                  src={video.video_thumbnail || noAccessImageActive} 
                  alt="thumbnail" 
                  className="listbump-thumbnail" 
                  onClick={() => handleVideoClick(video.id)}
                />
                <div className="listbump-title-button-wrapper">
                  <div
                    className="listbump-video-title clickable-title"
                    onClick={() => handleVideoClick(video.id)}
                  >
                  {truncateText(video.publish_title || video.video_file_name, device === 'mobile' ? 22 : device === 'tablet' ? 40 : 54)}
                  </div>
                  <div className="listbump-description-button-wrapper">
                    {hoveredIndex === index ? (
                      <button className="view-video-button" onClick={() => handleVideoClick(video.id)}>
                          <img src={viewVideoValue} alt="View Video" className="view-video-icon" />
                      </button>
                      ) : (
                        <div className="listbump-video-description">
                            {formatDate(video.video_upload_time)}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {(device === 'tablet' || device === 'desktop') && (
                <>
                  <div className="listbump-video-duration">{formatDuration(video.video_duration_in_seconds)}</div>
                  <div className="listbump-video-distribution">
                    {accessToken ? (
                      <img src={utubeLogoConnected} alt="YouTube Icon" className="utubedistribution-icon-active" />
                    ) : (
                      <img src={youtubeWhite} alt="YouTube Icon" className="utubedistribution-icon" />
                    )}
                  </div>
                  <div className="listbump-video-meta">{video.video_quality}</div>
                </>
              )}
              {device === 'desktop' && (
                <div className="listbump-video-meta">
                  <img src={bumpupsLogo} alt="bumped logo" className="listbump-bumped-logo" />
                </div>
              )}
            </div>
          ))}
          <div className="listbump-pagination-controls">
            <span className="listbump-pagination-info">
              {startVideoNumber}-{endVideoNumber} of about {videos.length}
            </span>
            <button 
              onClick={() => setCurrentPage(1)} 
              disabled={currentPage === 1}
              className="listbump-pagination-button"
            >
              <i className="listbump-outside-arrow listbump-double-left"></i>
            </button>
            <button 
              onClick={goToPreviousPage} 
              disabled={currentPage === 1}
              className="listbump-pagination-button"
            >
              <i className="listbump-arrow listbump-left"></i>
            </button>
            <button 
              onClick={goToNextPage} 
              disabled={currentPage === totalPages}
              className="listbump-pagination-button"
            >
              <i className="listbump-arrow listbump-right"></i>
            </button>
            <button 
              onClick={() => setCurrentPage(totalPages)} 
              disabled={currentPage === totalPages}
              className="listbump-pagination-button"
            >
              <i className="listbump-outside-arrow listbump-double-right"></i>
            </button>
          </div>
          
        </>
      ) : (
        <div className="no-content-wrapper">
          <img src={noAccessImageActive} alt="No content" className="no-content-image" />
          <div className="no-content-message">No content available</div>
        </div>
      )}
    </div>
  );
};

export default ListViewBumpUp;



// ) : (
//   <div className="listbump-video-description">
//       {!isTranscriptReady
//           ? formatDate(video.video_upload_time)
//           : bumpingTimeLeft[video.id] && bumpingTimeLeft[video.id] !== 'successfully bumped'
//               ? `Bumping ... ${bumpingTimeLeft[video.id]}`
//               : formatDate(video.video_upload_time)
//       }
//   </div>
// )}