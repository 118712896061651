// FIREBASE
import { ref, uploadBytesResumable, getDownloadURL, updateMetadata, deleteObject } from 'firebase/storage';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { storage, videoStorage, db } from '../firebase';

// FUNCTIONS
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import base64 from 'base-64';

const ffmpeg = new FFmpeg({ log: true });

const DEFAULT_THUMBNAIL_URL = process.env.REACT_APP_DEFAULT_THUMBNAIL_URL;

const validateFile = (file) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            if (video.duration > 0 && video.videoWidth > 0 && video.videoHeight > 0) {
                // console.log("File validation successful:", file.name);
                let videoQuality;
                if (video.videoWidth >= 3840) {
                    videoQuality = '4K';
                } else if (video.videoWidth >= 1920) {
                    videoQuality = 'HD';
                } else if (video.videoWidth >= 1280) {
                    videoQuality = '720';
                } else {
                    videoQuality = 'SD';
                }
                resolve({ videoQuality, videoDuration: video.duration });
            } else {
                // console.error("Invalid video file:", file.name);
                reject('Invalid video file');
            }
        };

        video.onerror = () => {
            window.URL.revokeObjectURL(video.src);
            // console.error("Error loading video metadata:", file.name);
            reject('Invalid video file');
        };

        video.src = URL.createObjectURL(file);
    });
};

const generate16DigitVideoId = () => {
    const uniqueId = uuidv4();
    const base64Id = base64.encode(uniqueId)
        .replace(/\+/g, '-') // Replace + with -
        .replace(/\//g, '_') // Replace / with _
        .replace(/=+$/, ''); // Remove ending =
    return base64Id.slice(0, 16);
};

const generateVideoId = async () => {
    // console.log("Starting to generate video ID...");

    let videoId = generate16DigitVideoId();
    // console.log("Generated initial video ID:", videoId);

    let videoDocRef = doc(db, "globalBumps", videoId);
    let videoDoc = await getDoc(videoDocRef);

    while (videoDoc.exists()) {
        // console.log("Video ID already exists. Generating a new one...");
        videoId = generate16DigitVideoId();
        // console.log("Generated new video ID:", videoId);
        videoDocRef = doc(db, "globalBumps", videoId);
        videoDoc = await getDoc(videoDocRef);
    }

    // console.log("Storing new video ID:", videoId);

    await setDoc(videoDocRef, { exists: true });
    // console.log("Stored new video ID successfully");

    return videoId;
};

const functions = getFunctions();
const getCaptionsBump = httpsCallable(functions, 'getCaptionsBump');

const uploadBump = async (file, user, setUploadProgress, setVideoId, setShowPopup, setThumbnailUrl, storagePathRef, uploadTaskRef, thumbnailUrl) => {
    if (!file) return;

    // console.log("Selected file:", file.name);

    if (file.size > 31 * 1024 * 1024 * 1024) {
        // console.error("File size exceeds the 31GB limit:", file.size);
        return;
    }

    let videoMetadata;
    try {
        videoMetadata = await validateFile(file);
        // console.log("Video metadata:", videoMetadata);
    } catch (validationError) {
        // console.error("File validation error:", validationError);
        return;
    }

    const generatedVideoId = await generateVideoId();
    setVideoId(generatedVideoId);
    // console.log("Generated video ID:", generatedVideoId);

    setShowPopup(true);
    
    // console.log('videoStorage:', videoStorage);

    const metadata = {
        contentType: file.type,
        customMetadata: {
            uploadedBy: user.uid,
            description: 'Video upload',
            videoFileName: file.name,
            videoThumbnail: thumbnailUrl || DEFAULT_THUMBNAIL_URL,
            videoQuality: videoMetadata.videoQuality,
            videoDuration: videoMetadata.videoDuration.toString()
        }
    };

    const storageRef = ref(videoStorage, `${user.uid}/${generatedVideoId}`);
    storagePathRef.current = storageRef;
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    uploadTaskRef.current = uploadTask;

    // console.log("Starting upload:", file.name);

    uploadTask.on('state_changed',
        (snapshot) => {
            const videoProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgress(videoProgress);
            // console.log(`Video upload progress: ${videoProgress}%`);
        },
        (error) => {
            // console.error("Upload error:", error);
        },
        async () => {
            try {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                // console.log('Video file available at', downloadURL);

                const thumbnailstorageRef = ref(storage, `bumpThumbnails/${user.uid}/${generatedVideoId}`);
                let thumbnailDownloadURL = thumbnailUrl;

                try {
                    thumbnailDownloadURL = await getDownloadURL(thumbnailstorageRef);

                    const newMetadata = {
                        customMetadata: {
                            videoThumbnail: thumbnailDownloadURL
                        }
                    };
                    await updateMetadata(storageRef, newMetadata);
                    setThumbnailUrl(thumbnailDownloadURL);
                } catch (error) {
                    // console.error('Thumbnail URL error:', error);
                }

                setUploadProgress(100);
                // console.log('Upload completed successfully');
                
                const payload = {
                    videoMetadata: {
                        uploadedBy: user.uid,
                        videoId: generatedVideoId,
                        description: 'Video upload',
                        videoFileName: file.name,
                        videoThumbnail: thumbnailDownloadURL || DEFAULT_THUMBNAIL_URL,
                        videoQuality: videoMetadata.videoQuality,
                        videoDuration: videoMetadata.videoDuration.toString()
                    }
                };

                await getCaptionsBump(payload);
                // console.log('CaptionBump Got Data');
                const localStorageKey = `videoCache_${user.uid}_BumpedVideos`;
                localStorage.removeItem(localStorageKey);
                localStorage.removeItem(`recentBump_${user.uid}`);

            } catch (error) {
                // console.error("Download URL error:", error);
            }
        }
    );
};



export { uploadBump, validateFile, generate16DigitVideoId, generateVideoId };