import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../myCreator/ThumbnailLayout.css'; 

// FIREBASE
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, onSnapshot, collection, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';

// FUNCTIONS
import { getYoutubeURLCaptionsPython } from '../myCreator/getVideoCaptions';
import { registerListener } from '../components/ListenerManager';
import he from 'he';

// IMPORT UI
import InsufficientTimePopUp from '../components/miscPages/InsufficientTimePopUp';

// IMAGES
import processVideoIcon from '../assets/images/upmove-v1.png';
import viewVideoValue from '../assets/images/v2-settings.png';
import bumpupsBadge from '../assets/images/bumpups-tans-white-v1.png';
import reloadAnalyticsLogo from '../assets/images/realod-v2.png';
import processlogo from '../assets/gifmedia/bumpups-2.gif';
import utubeLogo from '../assets/images/v2-creator.png';
import utubeLogoon from '../assets/images/v2-youtubeon.png';

const NonAuthThumbnailLayout = () => {
  const [videos, setVideos] = useState([]);
  const [activeTab, setActiveTab] = useState('all'); // 'all' or 'archive'

  const [doneVideos, setDoneVideos] = useState([]); 
  const [videoStats, setVideoStats] = useState({}); 
  const [initialVideoStats, setInitialVideoStats] = useState({});
  const [showActionMenu, setShowActionMenu] = useState(null);
  const [processingVideos, setProcessingVideos] = useState([]);
  const [insufficientTime, setInsufficientTime] = useState(false);
  const [videoFailureStatus, setVideoFailureStatus] = useState({});
  const [noCaptionsHere, setnoCaptionsHere] = useState(false);
  const [noAuthAllowed, setnoAuthAllowed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [currentLogo, setCurrentLogo] = useState(reloadAnalyticsLogo);

  const functions = getFunctions();
  const refreshVLCStats = httpsCallable(functions, 'refreshVLCStats');

  useEffect(() => {
    const fetchVideoData = async () => {
      const user = auth.currentUser;
      const userDocRef = doc(db, 'users', user.uid);
      const valueDataCollectionRef = collection(userDocRef, 'valueData');

      const unsubscribe = onSnapshot(valueDataCollectionRef, (querySnapshot) => {
        const doneVideoIds = [];
        const stats = {};
        const initialStats = {};

        querySnapshot.forEach((doc) => {
          const videoId = doc.id.startsWith('youtube-') ? doc.id.substring('youtube-'.length) : doc.id;
          doneVideoIds.push(videoId);

          stats[videoId] = {
            viewCount: doc.data().curr_view_count,
            likeCount: doc.data().curr_like_count,
            commentCount: doc.data().curr_comment_count,
          };

          initialStats[videoId] = {
            viewCount: doc.data().init_view_count,
            likeCount: doc.data().init_like_count,
            commentCount: doc.data().init_comment_count,
          };
        });

        setDoneVideos(doneVideoIds);
        setVideoStats(stats);
        setInitialVideoStats(initialStats);
      });

      registerListener(unsubscribe);

      return () => {
        unsubscribe();
      };
    };

    fetchVideoData();
  }, [showActionMenu]);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const userDocRef = doc(db, 'users', user.uid);
    const insufficientTimeRef = doc(userDocRef, 'subscriptionData', 'noTimeAvailable');

    const unsubscribe = onSnapshot(insufficientTimeRef, (docSnap) => {
      const isTimeInsufficient = docSnap.exists() && docSnap.data().insufficientTime;
      setInsufficientTime(isTimeInsufficient);

      if (isTimeInsufficient) {
        const cachedVideos = JSON.parse(localStorage.getItem('processingVideos') || '[]');
        cachedVideos.forEach(({ videoId }) => clearVideoFromCache(videoId));
        setProcessingVideos([]);
      }
    });

    registerListener(unsubscribe);

    return () => unsubscribe();
  }, []);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
      if (minutes > 0) {
        formattedDuration += `${minutes}m`;
      }
    } else {
      formattedDuration = `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    return formattedDuration.trim();
  };

  const handleYoutubeURLVideoProcessing = async (videoId, durationInSeconds, thumbnailUrl, title) => {
    const processingEndTime = Date.now() + 60000; // Current time + 60 seconds
    const newProcessingVideo = { videoId, processingEndTime };

    setProcessingVideos(prev => [...prev, videoId]);

    const cachedVideos = JSON.parse(localStorage.getItem('processingVideos') || '[]');
    localStorage.setItem('processingVideos', JSON.stringify([...cachedVideos, newProcessingVideo]));

    getYoutubeURLCaptionsPython(videoId, durationInSeconds, thumbnailUrl, title)
      .then(({ is503Error, isunAuthError }) => {
        if (is503Error) {
          setnoCaptionsHere(true);
          setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
          clearVideoFromCache(videoId);
          setVideoFailureStatus(prevStatus => ({
            ...prevStatus,
            [videoId]: true,
          }));

          setTimeout(() => {
            setVideoFailureStatus(prevStatus => ({
              ...prevStatus,
              [videoId]: false,
            }));
          }, 20000);
        } else if (isunAuthError) {
          setnoAuthAllowed(true);
          setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
          clearVideoFromCache(videoId);
          setVideoFailureStatus(prevStatus => ({
            ...prevStatus,
            [videoId]: true,
          }));

          setTimeout(() => {
            setVideoFailureStatus(prevStatus => ({
              ...prevStatus,
              [videoId]: false,
            }));
          }, 20000);
        } else {
          setTimeout(() => {
            setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
            clearVideoFromCache(videoId);

            setVideoFailureStatus(prevStatus => ({
              ...prevStatus,
              [videoId]: true,
            }));

            setTimeout(() => {
              setVideoFailureStatus(prevStatus => ({
                ...prevStatus,
                [videoId]: false,
              }));
            }, 20000); // 20 seconds
          }, 60000); // 60 seconds
        }
      })
      .catch(error => {
        setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
        clearVideoFromCache(videoId);
      });
  };

  const clearVideoFromCache = (videoId) => {
    const cachedVideos = JSON.parse(localStorage.getItem('processingVideos') || '[]');
    const updatedCache = cachedVideos.filter(v => v.videoId !== videoId);
    localStorage.setItem('processingVideos', JSON.stringify(updatedCache));
  };



  useEffect(() => {
    const restoreProcessingState = () => {
      const cachedVideos = JSON.parse(localStorage.getItem('processingVideos') || '[]');
      const now = Date.now();

      cachedVideos.forEach(({ videoId, processingEndTime }) => {
        if (processingEndTime > now) {
          setProcessingVideos(prev => [...prev, videoId]);
          setTimeout(() => {
            setProcessingVideos(prev => prev.filter(vId => vId !== videoId));
            clearVideoFromCache(videoId);
          }, processingEndTime - now);
        } else {
          clearVideoFromCache(videoId);
        }
      });
    };

    restoreProcessingState();
  }, []);



  useEffect(() => {
    const localStorageKey = `youtubeUrlCache_${auth.currentUser.uid}`;
  
    const fetchVideos = async () => {
      const cachedVideos = localStorage.getItem(localStorageKey);
      if (cachedVideos) {
        // console.log('Fetching videos from localStorage:', JSON.parse(cachedVideos));
        const uniqueVideos = removeDuplicates(JSON.parse(cachedVideos)); // Remove duplicates
        setVideos(uniqueVideos);
        return; // Exit if videos were found in local storage
      }
  
      // console.log('Fetching videos from Firestore');
      const videoCacheRef = doc(db, "users", auth.currentUser.uid, "contentCatalog", "youtubeUrlCatalog");
      const cacheSnap = await getDoc(videoCacheRef);
      if (cacheSnap.exists()) {
        const videos = cacheSnap.data().videos.map(video => ({
          ...video,
          isArchived: video.isArchived || false // Default to false if isArchived doesn't exist
        }));
        
        const uniqueVideos = removeDuplicates(videos); // Remove duplicates
        setVideos(uniqueVideos);
        localStorage.setItem(localStorageKey, JSON.stringify(uniqueVideos)); // Store the fetched videos with the correct isArchived status
        // console.log('Videos fetched from Firestore and saved to localStorage:', uniqueVideos);
      } else {
        // console.log('No data found in Firestore');
      }
    };
  
    // Helper function to remove duplicate videos based on videoId
    const removeDuplicates = (videos) => {
      const seen = new Set();
      return videos.filter(video => {
        const duplicate = seen.has(video.videoId);
        seen.add(video.videoId);
        return !duplicate;
      });
    };
  
    fetchVideos();
  }, []);
  
  
  
   // Add this line to calculate the number of processed videos
   const numberOfVideos = videos.filter(video => !video.isArchived).length;

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const handleArchiveVideo = async (videoId, shouldArchive) => {
    try {
      // console.log(`Archiving/unarchiving video: ${videoId}, shouldArchive: ${shouldArchive}`);
      
      const updatedVideos = videos.map(video => 
        video.videoId === videoId ? { ...video, isArchived: shouldArchive } : video
      );
      setVideos(updatedVideos);
  
      const videoCacheRef = doc(db, "users", auth.currentUser.uid, "contentCatalog", "youtubeUrlCatalog");
      await setDoc(videoCacheRef, { videos: updatedVideos }, { merge: true });
      // console.log('Updated videos saved to Firestore:', updatedVideos);
  
      // Clear localStorage to ensure fresh data is fetched next time
      const localStorageKey = `youtubeUrlCache_${auth.currentUser.uid}`;
      localStorage.removeItem(localStorageKey);
      // console.log('LocalStorage cleared after archiving/unarchiving');
  
      // Re-save updated videos to localStorage
      localStorage.setItem(localStorageKey, JSON.stringify(updatedVideos));
      // console.log('Updated videos saved to localStorage:', updatedVideos);
  
    } catch (error) {
      // console.error("Error updating video archive status:", error);
    }
  };
  

  const renderTitle = (title) => {
    const decodedTitle = he.decode(title);
    return decodedTitle.length > 37 ? `${decodedTitle.substring(0, 37)}...` : decodedTitle;
  };

  const formatStatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num;
    }
  };

  const handleReloadClick = async () => {
    try {
      setIsProcessing(true);
      setCurrentLogo(processlogo);
      await refreshVLCStats();
    } catch (error) {
      // console.error("Error refreshing stats:", error);
    } finally {
      setIsProcessing(false);
      setCurrentLogo(reloadAnalyticsLogo);
      setIsCooldown(true);
      setTimeout(() => setIsCooldown(false), 5000);
    }
  };
  

  const [hoveredVideoId, setHoveredVideoId] = useState(null);



  return (
    <div className="unauth-thumbnail-layout">
      <div className="tabs-container">
        <button
          className="reload-button-unauth"
          data-hover-text="Get Latest Stats"
          onClick={handleReloadClick}
          disabled={isProcessing || isCooldown}
          style={{
            cursor: isCooldown || isProcessing ? 'not-allowed' : 'pointer',
            opacity: isCooldown || isProcessing ? 0.5 : 1,
            backgroundColor: isProcessing ? 'rgba(74, 221, 128, 0.5)' : 'initial',
            position: 'relative'
          }}
        >
          <img src={currentLogo} alt="Reload Analytics" className="reload-analytics-logo-unauth" />
        </button>

        <div 
          className={`tab ${activeTab === 'all' ? 'active' : ''}`} 
          onClick={() => handleTabChange('all')}
        >
          Processed videos (<b>{numberOfVideos}</b>)
        </div>
        <div 
          className={`tab ${activeTab === 'archive' ? 'active' : ''}`} 
          onClick={() => handleTabChange('archive')}
        >
          Archive
        </div>
      </div>

      <div className="thumbnail-grid">
        {videos.filter(video => {
          if (activeTab === 'all') return !video.isArchived;
          if (activeTab === 'archive') return video.isArchived;
          return true;
        }).map((video, index) => (
          <div key={index} className="videocontent-container">

            <div className="thumbnail-wrapper">
            <div 
              className="thumbnail-item"
              onMouseEnter={() => setHoveredVideoId(video.videoId)}
              onMouseLeave={() => setHoveredVideoId(null)}
            >
              <div 
                className={`duration-badge ${hoveredVideoId === video.videoId && (activeTab === 'archive' || !video.isArchived) ? 'archive-active' : ''}`}
                onClick={() => handleArchiveVideo(video.videoId, !video.isArchived)}
              >
                {hoveredVideoId === video.videoId 
                  ? activeTab === 'archive' 
                    ? 'Unarchive' 
                    : 'Archive'
                  : formatDuration(video.durationInSeconds)}
              </div>
              <div className={
                doneVideos.includes(video.videoId) ? "timestamp-badge done" :
                processingVideos.includes(video.videoId) && !insufficientTime ? "timestamp-badge in-queue" :
                "timestamp-badge"
              }>
                <img src={bumpupsBadge} alt="Timestamp Badge" />
              </div>
              <img src={video.thumbnailUrl} alt="Thumbnail" />
              {doneVideos.includes(video.videoId) && (
                <Link to={`/video/id-${video.videoId}`}>
                  <div className="done-video-overlay"></div>
                </Link>
                  )}
                {videoFailureStatus[video.videoId] && 
                  !doneVideos.includes(video.videoId) && (
                    <div className="error-overlay">
                      <div className="error-message-box">
                        {noCaptionsHere ? (
                          <div className="error-message">Captions are not available for this video! 🚫</div>
                        ) : noAuthAllowed ? (
                          <div className="error-message">User has not allowed full YouTube access, unable to process! 🔒</div>
                        ) : (
                          <div className="error-message">Processing taking longer than usual... ⏳</div>
                        )}

                        <div className="retry-message">
                          Learn more <a href="https://intercom.help/tubestamp/en/articles/9126173-why-won-t-my-videos-process" target="_blank" rel="noopener noreferrer">here</a>.
                        </div>
                      </div>
                    </div>
                  )}
                 <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer" className="youtube-link-button">
                  <img src={doneVideos.includes(video.videoId) ? utubeLogoon : utubeLogo} alt="YouTube Link" className="youtube-link-icon" />
                </a>
              </div>

              <div className="info-action-wrapper">
                <div className="info-rectangle">
                  
                <div className="video-title">
                    {renderTitle(video.title)}
                  </div>
                  <div className="action-buttons videolayout">
                    {!doneVideos.includes(video.videoId) && (
                      (processingVideos.includes(video.videoId) && !insufficientTime) ? 
                        <button className="icon-button processing">
                          <img src={processlogo} alt="Processing" className="icon" />
                        </button> :
                        <button className="icon-button not-done" onClick={async () => {
                          await handleYoutubeURLVideoProcessing(video.videoId, video.durationInSeconds, video.thumbnailUrl, video.title);
                        }}>
                          <img src={processVideoIcon} alt="Process" className="icon" />
                          <span className="process-tooltip">Process Video</span>
                        </button>
                    )}
                    {doneVideos.includes(video.videoId) && (
                      <Link to={`/video/id-${video.videoId}`} state={{ from: '/links' }} className="icon-button done">
                        <img src={viewVideoValue} alt="More" className="icon" />
                      </Link>
                    )}
                  </div>

                </div>
              </div>
            </div>

            <div className="video-stats-container">
              <div className={`video-stats ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.viewCount != null && videoStats[video.videoId]?.likeCount != null && videoStats[video.videoId]?.commentCount != null ? '' : 'dimmed'}`}>
                <div className="video-stat">
                  <div className="stat-icon">Views</div>
                  <div className={`stat-box ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.viewCount != null && videoStats[video.videoId]?.viewCount !== initialVideoStats[video.videoId]?.viewCount ? 'number' : 'default'}`}>
                    {doneVideos.includes(video.videoId) && videoStats[video.videoId]?.viewCount != null
                      ? <>
                          {formatStatNumber(videoStats[video.videoId].viewCount)}
                          {videoStats[video.videoId]?.viewCount > initialVideoStats[video.videoId]?.viewCount && (
                            <span className="increase">+{formatStatNumber(videoStats[video.videoId].viewCount - initialVideoStats[video.videoId].viewCount)}</span>
                          )}
                        </>
                      : '--'}
                  </div>
                </div>
                <div className="video-stat">
                  <div className="stat-icon">Likes</div>
                  <div className={`stat-box ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.likeCount != null && videoStats[video.videoId]?.likeCount !== initialVideoStats[video.videoId]?.likeCount ? 'number' : 'default'}`}>
                    {doneVideos.includes(video.videoId) && videoStats[video.videoId]?.likeCount != null
                      ? <>
                          {formatStatNumber(videoStats[video.videoId].likeCount)}
                          {videoStats[video.videoId]?.likeCount > initialVideoStats[video.videoId]?.likeCount && (
                            <span className="increase">+{formatStatNumber(videoStats[video.videoId].likeCount - initialVideoStats[video.videoId].likeCount)}</span>
                          )}
                        </>
                      : '--'}
                  </div>
                </div>
                <div className="video-stat">
                  <div className="stat-icon">Comments</div>
                  <div className={`stat-box ${doneVideos.includes(video.videoId) && videoStats[video.videoId]?.commentCount != null && videoStats[video.videoId]?.commentCount !== initialVideoStats[video.videoId]?.commentCount ? 'number' : 'default'}`}>
                    {doneVideos.includes(video.videoId) && videoStats[video.videoId]?.commentCount != null
                      ? <>
                          {formatStatNumber(videoStats[video.videoId].commentCount)}
                          {videoStats[video.videoId]?.commentCount > initialVideoStats[video.videoId]?.commentCount && (
                            <span className="increase">+{formatStatNumber(videoStats[video.videoId].commentCount - initialVideoStats[video.videoId].commentCount)}</span>
                          )}
                        </>
                      : '--'}
                  </div>
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>

      {insufficientTime && <InsufficientTimePopUp />}

    </div>
  );
};

export default NonAuthThumbnailLayout;
