// FIREBASE
import { getFunctions, httpsCallable } from 'firebase/functions';

// INITIALIZE FUNCTIONS
const functions = getFunctions();
const getCaptionsBump = httpsCallable(functions, 'getCaptionsBump');

const reprocessTranscript = async ({ user, generatedVideoId, videoTitle, videoQuality, videoDuration, isReprocess }) => {
    // Commented out the console logs
    // console.log('Reprocessing transcript with the following details:');
    // console.log('User ID:', user.uid);  // This should now print the correct user object
    // console.log('Generated Video ID:', generatedVideoId);
    // console.log('Video Title:', videoTitle);
    // console.log('Video Quality:', videoQuality);
    // console.log('Video Duration:', videoDuration);
    // console.log('Is Reprocess:', isReprocess);

    const payload = {
        videoMetadata: {
            uploadedBy: user.uid,  // Access user.uid if user is an object
            videoId: generatedVideoId,
            description: videoTitle,
            videoFileName: videoTitle,
            videoQuality: videoQuality,
            videoDuration: videoDuration,
            isReprocess: isReprocess  // Include the isReprocess flag in the payload
        }
    };

    // Commented out the payload log
    // console.log('Payload for getCaptionsBump:', payload);

    try {
    await getCaptionsBump(payload);
        // console.log('Transcript reprocessed successfully. Response:', response);
    } catch (error) {
        // console.error('Error reprocessing transcript:', error);
    }
};

export { reprocessTranscript };
