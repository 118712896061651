import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'; 
import LoadingBar from 'react-top-loading-bar';
import './nonCreatorDashboard.css';

//FIREBASE
import { doc, onSnapshot, collection, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../firebase';

//FUNCTIONS
import LinksNotification from '../components/linksNotification'; // Adjust the path as needed
import AppTransition from '../components/AppTransition';
import axios from 'axios';
import { debounce } from 'lodash';
import { getYoutubeURLCaptionsPython } from '../myCreator/getVideoCaptions';
import { registerListener } from '../components/ListenerManager';

//IMPORT UI
import NonAuthThumbnailLayout from './nonAuthThumbnailLayout';
import Sidebar from '../myDashboard/Sidebar';
import CloseSidebar from '../myDashboard/CloseSidebar';

//IMAGES
import processBumpupslogo from '../assets/gifmedia/bumpups-2.gif';
import infoLogo from '../assets/images/info-v1.png';
import placeholderThumbnail from '../assets/images/tube_previewthumbnail.png';
import linkIcon from '../assets/images/link-v3.png';


function NonCreatorDashboard({ isSidebarOpen, toggleSidebar }) {

    const [youtubeVideoDetails, setYoutubeVideoDetails] = useState({});
    const [youtubeInputUrl, setYoutubeInputUrl] = useState(''); // Initialize as an empty string

    // Step 1: Function to extract the YouTube video ID from the URL
    const extractYoutubeVideoID = (url) => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|youtube\.com\/live\/)([^#&?]*).*/;
      const match = url.match(regExp);

      // The video ID should be 11 characters long and consist of valid characters.
      return (match && /^[a-zA-Z0-9_-]{11}$/.test(match[2])) ? match[2] : null;
    };



  
    const convertDurationToSeconds = (duration) => {
        if (!duration) return 0; // Return 0 if duration is null or undefined
      
        let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
        let hours = (parseInt(match[1]) || 0);
        let minutes = (parseInt(match[2]) || 0);
        let seconds = (parseInt(match[3]) || 0);
        return hours * 3600 + minutes * 60 + seconds;
      };

    const [isSearching, setIsSearching] = useState(false);
    const [istoLong, setistoLong] = useState(false);


    const [noValidVideo, setnoValidVideo] = useState(false);

// Function to fetch YouTube video details
const fetchYoutubeVideoDetails = async (url) => {
    const videoId = extractYoutubeVideoID(url);
    if (!videoId) {
    //   console.log('Please enter a valid YouTube URL.');
      return;
    }
    setIsSearching(true);
    const apiYoutubeKey = process.env.REACT_APP_YOUTUBE_API_KEY; // Using environment variable for API key
    try {
      const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
        params: {
          part: 'snippet,contentDetails',
          id: videoId,
          key: apiYoutubeKey,
        }
      });
      // Step 3: Handle the response
      if (response.data.items.length > 0) {
        const { snippet, contentDetails } = response.data.items[0];
        const durationSeconds = convertDurationToSeconds(contentDetails.duration);
        // console.log(`Video Duration: ${durationSeconds} seconds, Exceeds 3.5 hours: ${durationSeconds > 12600}`);
        setistoLong(durationSeconds > 12600);
        // Fallback chain for thumbnail resolution
        const thumbnailUrl = snippet.thumbnails.maxres?.url || snippet.thumbnails.standard?.url || snippet.thumbnails.high?.url;
        if (!thumbnailUrl) {
        //   console.log('No suitable thumbnail resolution found.');
          setIsSearching(false); // Reset isSearching state
          return; // Exit if there's no thumbnail to use
        }
        const language = snippet.defaultLanguage || 'Language unspecified'; // or any other default you prefer
        const youtubeDetails = {
          thumbnailUrl: thumbnailUrl,
          duration: contentDetails.duration,
          language: language,
          captionAvailable: contentDetails.caption === 'false',
          videoId: videoId,
          title: snippet.title,
        };
        setYoutubeVideoDetails(youtubeDetails);
        // Note: Not resetting youtubeInputUrl here to keep the URL in the input box
        // console.log('YouTube Video Details:', youtubeDetails);
        setIsSearching(false);
      } else {
        // console.log('No YouTube video details found.');
        setnoValidVideo(true);
        setIsSearching(false); // Reset isSearching state
      }
    } catch (error) {
    //   console.error('Error fetching YouTube video details:', error);
      setIsSearching(false); // Reset isSearching state
    }
  };

    const [isOverTimebank, setOverTimebank] = useState(false);

    useEffect(() => {
      const user = auth.currentUser;
      if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const timebankRef = doc(userDocRef, 'subscriptionData', 'timeWarden');
  
          const fetchTimebank = async () => {
              try {
                  const docSnap = await getDoc(timebankRef);
                  if (docSnap.exists()) {
                      const { timeBank, timeBankBoost, timeBankBump } = docSnap.data();
  
                      // Convert all timebank values to seconds
                      const timebankInSeconds = (timeBank || 0) * 60;
                      const timeBankBoostInSeconds = (timeBankBoost || 0) * 60;
                      const timeBankBumpInSeconds = (timeBankBump || 0) * 60;
  
                      // Total timebank in seconds
                      const totalTimebankInSeconds = timebankInSeconds + timeBankBoostInSeconds + timeBankBumpInSeconds;
  
                      if (youtubeVideoDetails) {
                          const videoDurationInSeconds = convertDurationToSeconds(youtubeVideoDetails.duration);
  
                          if (totalTimebankInSeconds >= videoDurationInSeconds) {
                              setOverTimebank(false);
                          } else {
                              setOverTimebank(true);
                          }
                      }
                  }
              } catch (error) {
                  // console.error('Error fetching timebank:', error);
              }
          };
  
          fetchTimebank();
      }
  }, [youtubeVideoDetails]);
  

    

    function formatDuration(duration) {
        // Match against the ISO 8601 duration format
        const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
      
        let hours = match[1] ? parseInt(match[1].slice(0, -1), 10) : 0;
        let minutes = match[2] ? parseInt(match[2].slice(0, -1), 10) : 0;
      
        // Build formatted duration string
        let formatted = '';
        if (hours > 0) formatted += `${hours}h `;
        if (minutes > 0 || (minutes === 0 && hours === 0)) formatted += `${minutes}m`;
      
        return formatted.trim(); // Trim any extra space at the end
    }
    
    function getLanguageName(languageCode) {
        // Check if languageCode is valid and not the placeholder text
        if (!languageCode || languageCode === 'Language unspecified') {
          return null; // Or return 'Language Unspecified' if you want to display a message instead of null
        }
      
        try {
          const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
          return languageNames.of(languageCode);
        } catch (error) {
        //   console.error('Error getting language name:', error);
          return null; // Return null or a default message in case of an error
        }
      }
      


    
      const [isShortTimestamp, setIsShortTimestamp] = useState(false); 

      useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            const timestampSettingsRef = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');
            getDoc(timestampSettingsRef)
                .then(docSnap => {
                    if (docSnap.exists()) {
                        // Update the checkbox state to match the Firestore setting
                        setIsShortTimestamp(docSnap.data().isShortTimestamps || false);
                    }
                })
                .catch(error => {
                    // console.error("Error fetching timestamp length preference:", error);
                });
        }
    }, []); 

    const debouncedUpdateTimestampLength = debounce(async (isShort) => {
            const user = auth.currentUser;
            if (user) {
                const timestampSettingsRef = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');
        
                try {
                    await updateDoc(timestampSettingsRef, {
                        isShortTimestamps: isShort
                    });
                    // console.log('Timestamp length preference updated successfully.');
                } catch (error) {
                    // console.error('Error updating timestamp length preference:', error);
                    // Optionally, revert UI change in case of error
                }
            }
            setIsShortTimestamp(isShort);
        }, 500);


        const handleTimestampLengthChange = (event) => {
            debouncedUpdateTimestampLength(event.target.checked);
        };



      const [showVideoNotFound, setShowVideoNotFound] = useState(false);

// New input change handler
const handleUrlChange = (url) => {
    const videoId = extractYoutubeVideoID(url);
    setYoutubeInputUrl(url);
    setnoValidVideo(false);
    setistoLong(false);
    setOverTimebank(false);
    if (videoId) {
      // Reset any previous "Video Not Found" message
      setShowVideoNotFound(false);
      // Fetch video details
      fetchYoutubeVideoDetails(url);
    } else {
      // Clear previous video details and show "Video Not Found" message
      setYoutubeVideoDetails({});
      setShowVideoNotFound(true);
    }
  };




  const [progress, setProgress] = useState(0);

  const [doneVideos, setDoneVideos] = useState([]); 
  const [processingStatus, setProcessingStatus] = useState({}); // Tracks processing status for each videoId
  const [isvideoProcessFailed, setisvideoProcessFailed] = useState({});
  const [isinternalError, setisinternalError] = useState((false));


  const progressIntervalRef = useRef(null);

  const incrementProgress = () => {
    progressIntervalRef.current = setInterval(() => {
      setProgress(prev => {
        if (prev >= 95) {
          clearInterval(progressIntervalRef.current);
          return prev;
        }
        return prev + 5;
      });
    }, 10000); // Adjust the interval time as needed
  };

  const handleYoutubeURLProcessing = async () => {
    setIsSearching(true);
    const videoId = youtubeVideoDetails.videoId;
    const durationInSeconds = convertDurationToSeconds(youtubeVideoDetails.duration);

    setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: true }));
    setProgress(10);

    getYoutubeURLCaptionsPython(
      videoId,
      durationInSeconds,
      youtubeVideoDetails.thumbnailUrl,
      youtubeVideoDetails.title,
      false
    )
      .then(({ isinternalError }) => {
        if (isinternalError) {
          setisinternalError(true);
          setIsSearching(false);
          setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: false }));
          setisvideoProcessFailed(prevStatus => ({
            ...prevStatus,
            [videoId]: true,
          }));
          setTimeout(() => {
            setisvideoProcessFailed(prevStatus => ({
              ...prevStatus,
              [videoId]: false,
            }));
          }, 20000);
          setProgress(0);
        } else {
          setProgress(50);
          incrementProgress();
        }
      })
      .catch(error => {
        setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: false }));
        setProgress(0);
      });

    setTimeout(() => {
      setisvideoProcessFailed(prevStatus => ({ ...prevStatus, [videoId]: true }));
      setTimeout(() => {
        setisvideoProcessFailed(prevStatus => ({
          ...prevStatus,
          [videoId]: false,
        }));
      }, 20000);
      setProcessingStatus(prevStatus => ({ ...prevStatus, [videoId]: false }));
      setIsSearching(false);
      clearInterval(progressIntervalRef.current);
      setProgress(0);
    }, 180000);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user && youtubeVideoDetails.videoId) {
      const userDocRef = doc(db, 'users', user.uid);
      const valueDataCollectionRef = collection(userDocRef, 'valueData');

      const unsubscribe = onSnapshot(valueDataCollectionRef, async (querySnapshot) => {
        const processedVideoIds = querySnapshot.docs.map(doc => doc.id.startsWith('youtube-') ? doc.id.substring('youtube-'.length) : doc.id);

        if (processedVideoIds.includes(youtubeVideoDetails.videoId)) {
          const truncatedTitle = youtubeVideoDetails.title.length > 52 ? youtubeVideoDetails.title.substring(0, 52) + "..." : youtubeVideoDetails.title;
          const newVideoData = {
            durationInSeconds: convertDurationToSeconds(youtubeVideoDetails.duration),
            thumbnailUrl: youtubeVideoDetails.thumbnailUrl,
            title: truncatedTitle,
            videoId: youtubeVideoDetails.videoId,
          };

          const videoCacheRef = doc(db, "users", user.uid, "contentCatalog", "youtubeUrlCatalog");
          try {
            const docSnap = await getDoc(videoCacheRef);
            if (!docSnap.exists()) {
              await setDoc(videoCacheRef, { videos: [newVideoData] });
            } else {
              await updateDoc(videoCacheRef, {
                videos: arrayUnion(newVideoData)
              });
            }

            // Clear localStorage to ensure fresh data is fetched next time
            const localStorageKey = `youtubeUrlCache_${auth.currentUser.uid}`;
            localStorage.removeItem(localStorageKey);

            setProgress(100); // Set progress to 100% only when processing is complete
            setProcessingStatus(prevStatus => ({ ...prevStatus, [youtubeVideoDetails.videoId]: false }));
            setIsSearching(false);
            clearInterval(progressIntervalRef.current); // Stop the progress increment
          } catch (error) {
            setProgress(0);
          }
        }
      });

      return () => unsubscribe();
    }
  }, [youtubeVideoDetails.duration, youtubeVideoDetails.thumbnailUrl, youtubeVideoDetails.title, youtubeVideoDetails.videoId]);

  useEffect(() => {
    const fetchProcessedVideos = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const valueDataCollectionRef = collection(userDocRef, 'valueData');

        const unsubscribe = onSnapshot(valueDataCollectionRef, (querySnapshot) => {
          const processedVideoIds = querySnapshot.docs.map(doc => doc.id.startsWith('youtube-') ? doc.id.substring('youtube-'.length) : doc.id);

          setDoneVideos(processedVideoIds);

          processedVideoIds.forEach(videoId => {
            setProcessingStatus(prevStatus => {
              if (prevStatus[videoId]) {
                return { ...prevStatus, [videoId]: false };
              }
              return prevStatus;
            });
          });
        });

        return unsubscribe;
      }
    };

    fetchProcessedVideos();
  }, []);



      return (
        <div className="uploaddashboard">
       <LoadingBar color="#4add80" progress={progress} />

              {isSidebarOpen && <Sidebar />}
        {!isSidebarOpen && <CloseSidebar />}
        <AppTransition>

        <div className="dashboard-container">
        <LinksNotification />

        <section className="urlinput-section">
            <div className="urlinput-container">
              <div className="input-icon-wrapper">
                <img src={linkIcon} alt="Link Icon" className="url-input-icon" />
                <input
                  type="text"
                  id="urlInput"
                  className="url-input-box"
                  placeholder="Drop a Youtube link..."
                  value={youtubeInputUrl}
                  onChange={(e) => handleUrlChange(e.target.value)}
                />
              </div>

              <div>
                {!processingStatus[youtubeVideoDetails.videoId] &&
                  !doneVideos.includes(youtubeVideoDetails.videoId) &&
                  !isOverTimebank &&
                  !istoLong && (
                    <button
                      className={`url-input-button 
                                  ${!youtubeInputUrl || !extractYoutubeVideoID(youtubeInputUrl) || isSearching || noValidVideo ? 'disabled' : ''} 
                                  ${isSearching ? 'searching' : ''}`}
                      onClick={handleYoutubeURLProcessing}
                    >
                      {noValidVideo ? 'Not Valid URL' : (isSearching ? 'Searching...' : 'Process Video')}
                    </button>
                  )}

                {processingStatus[youtubeVideoDetails.videoId] && (
                  <button className="url-input-button processing">
                    Stay on page...
                  </button>
                )}

                {isOverTimebank && !doneVideos.includes(youtubeVideoDetails.videoId) && (
                  <Link to="/plans" className="url-input-button timebank-over">
                    Get More Time
                  </Link>
                )}

                {istoLong && !doneVideos.includes(youtubeVideoDetails.videoId) && !isOverTimebank && (
                  <button className="url-input-button max-duration-warning">
                    Max Duration 3h 30m
                  </button>
                )}

                {doneVideos.includes(youtubeVideoDetails.videoId) && (
                  <Link to={`/video/id-${youtubeVideoDetails.videoId}`} state={{ from: '/links' }} className="url-input-button videoDataReady">
                    View Bump
                  </Link>
                )}
              </div>
            </div>
          </section>

                                <section className="youtube-video-details">
                {youtubeInputUrl && youtubeVideoDetails.thumbnailUrl && (
                    <div className="video-card">
                    <div className="video-metadata">
                        {
                        youtubeVideoDetails.language && youtubeVideoDetails.language !== 'Language unspecified'
                        ? <div className="video-caption">Caption: <b>{getLanguageName(youtubeVideoDetails.language)}</b></div>
                        : <div className="video-caption" aria-hidden="true">&#160;</div> // Use aria-hidden for accessibility
                        }
                        <div className="video-credit-usage">Credit Cost: <b>{formatDuration(youtubeVideoDetails.duration)}</b></div>
                    </div>
                    <div className="video-overlay">
                        {/* Placeholder for potential overlay content */}
                    </div>


                    <div className="video-thumbnail-container" style={{ position: 'relative' }}>
                        <img 
                            src={youtubeVideoDetails.thumbnailUrl || placeholderThumbnail} 
                            alt="Video Thumbnail" 
                            className="video-thumbnail"
                        />

                        {processingStatus[youtubeVideoDetails.videoId] && (
                            <>
                                <div className="video-thumbnail-overlay"></div> 
                                <img src={processBumpupslogo} alt="Processing" className="processing-gif" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }} />
                            </>
                        )}

                        {isvideoProcessFailed[youtubeVideoDetails.videoId] && !doneVideos.includes(youtubeVideoDetails.videoId) && (
                            <div className="error-video-thumbnail-overlay">
                                 <div className="unauth-error-message-box">
                                 {isinternalError ? (
                              <div className="unauth-error-message">Captions are not available! 🚫</div>
                            ) :  (
                              <div className="unauth-error-message">Processing taking longer than usual... ⏳</div>
                            )}             
                            
                            {/* <div className="unauth-retry-message">
                                Learn more <a href="https://intercom.help/bumpups/en/articles/9126173-why-won-t-my-videos-process" target="_blank" rel="noopener noreferrer">here</a>.
                                </div> */}


                                </div>
                            </div>
                            )}

                    </div>

                    </div>
                    
                )}
                </section>
                {
                    // Only display the toggle-switch-container if youtubeInputUrl is a valid YouTube URL
                    youtubeInputUrl && extractYoutubeVideoID(youtubeInputUrl) && (
                        <div className="toggle-switch-container">
                            <span className="toggle-switch-text"><b>Make timestamp length short?</b></span>
                            <a href="https://intercom.help/bumpups/en/articles/9108586-what-timestamp-length-is-right-for-your-content" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                                    <img src={infoLogo} alt="Info" className="info-icon" />
                                    </a>
                            <label className="toggle-switch-label">
                            <input
                                type="checkbox"
                                checked={isShortTimestamp}
                                onChange={handleTimestampLengthChange}
                            />
                            <span className="toggle-slider"></span>
                            </label>
                        </div>
                    )
                }
                {
                showVideoNotFound && youtubeInputUrl && ( // Add `youtubeInputUrl` to make sure there's some input
                <div className="unauth-video-not-found">
                <div>
                    <div className="unauth-main-text">Video Not Found 🔎</div>
                    <div className="unauth-small-text">
                        check your video id:
                        <span className="unauth-example-url">https://www.youtube.com/watch?v=<span className="highlight-video-id">kL4gyBPkcv8</span></span>
                        <br />
                        supports videos from 5 to 240 minutes ⏲️
                    </div>
                </div>
            </div>
                )
                }
                { !youtubeInputUrl &&
            <section className="video-type-section">
                <div className="video-type-info">
                    <p>Our algorithm is optimized for videos with <b>SPOKEN</b> content. At present, it supports processing videos via YouTube links.</p>
                </div>
                <div className="video-types">
                    <div className="supported-types">
                        <p>Podcasts</p>
                        <p>Educational Videos</p>
                        <p>Commentaries Videos</p>
                        <p>Product Reviews</p>
                        <p>Streams</p>
                        <p>Meetings</p>
                        <p>Vlog</p>
                    </div>
                    <div className="unsupported-types">
                        <p>Music Videos</p>
                        <p>Soundless Videos</p>
                        <p>Songs</p>
                    </div>
                </div>
            </section>
                }
        <NonAuthThumbnailLayout />
        </div>

            {/* <div className="upload-area">
                <div className="file-drop-area">
                    <label htmlFor="fileInput" className="file-input-label">
                        <input type="file" id="fileInput" hidden />
                        Choose a file (MP4, MOV, WEBM), or drag it here
                    </label>
                </div>
            </div> */}
          </AppTransition>


        </div>
        
    );
}

export default NonCreatorDashboard;