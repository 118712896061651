import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './PricingPage.css';

//FUNCTIONS
import { AuthProvider } from '../AuthContext';

//IMPORT UI
import SellingPoints from '../components/miscPages/SellingPoints';
import Footer from './Footer';

//IMAGES
import pricellamaLogo from '../assets/images/pricellama-Page.jpg';


const PricingPage = () => {
  const [selectedPlan] = useState('Bump');
  const [selectedMinutes, setSelectedMinutes] = useState(100);
  const navigate = useNavigate();
  // const handlePlanChange = (e) => {
  //   setSelectedPlan(e.target.value);
  // }; 

  const handleMinutesChange = (e) => {
    setSelectedMinutes(e.target.value);
  };

  const calculatePrice = (minutes) => {
    // Assuming the price calculation is $1.99 per 100 minutes
    return (minutes / 100 * 1.99).toFixed(2);
  };

  const handleSignUp = () => {
    navigate('/signup');
};

// Pricing
  return (
    <AuthProvider>
      <Helmet>
        <title>Bumpups - Pricing</title>
        <meta name="description" content="Our AI-powered platform enhances your video content." />
      </Helmet>
      <div className="pricing-page-container-home">

        <div className="pricing-container-home">

        <div className="pricing-plan-chooser">

          {selectedPlan === 'Bump' && (
            <div className="essential-info-main">
              <p className="monthly-text">Monthly ✏️</p>
              <p className="price-text">${calculatePrice(selectedMinutes)} USD</p>
              <p className="upload-minutes-textpreview">{selectedMinutes} upload minutes</p>
              <p className="expiry-info-text">[Receive monthly upload time]</p>
              <select className="minutes-select plan-select" onChange={handleMinutesChange} value={selectedMinutes}>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={400}>400</option>
                <option value={600}>600</option>
                <option value={800}>800</option>
                <option value={1000}>1000</option>
              </select>
              
              
              <button className="proceed-btn-price" onClick={handleSignUp}>Get 60 Minutes For Free</button>

            </div>
          )}
           </div>
      <img src={pricellamaLogo} alt="Bumpups" className="priceLlama"/>

        </div>
        <SellingPoints />
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default PricingPage;
