import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

//FIREBASE
import { db, auth } from '../firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

//FUNCTIONS
import { AuthContext } from '../AuthContext';

//IMPORT UI
import CloseSidebar from './CloseSidebar';

//IMAGES
import bumpUPlogo from '../assets/images/bumparrow.png';
import helpIcon from '../assets/images/v2-helpicon.png';
import dashboardLogo from '../assets/images/dashboard-logo-v6.png';
import creatoricon from '../assets/images/v2-creator.png';
import subicon from '../assets/images/v2-plan.png';
import linkIcon from '../assets/images/link-v3.png';


function Sidebar() {
  const [currentView, setCurrentView] = useState(window.location.pathname);
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 1280);


  const initialState = sessionStorage.getItem(`currentPlan_${auth.currentUser?.uid}`) || 'Free Plan';
  const [currentPlan, setCurrentPlan] = useState(initialState);

  


  const { currentUser } = useContext(AuthContext);

  useEffect(() => {

    const updateView = () => {
      setCurrentView(window.location.pathname);
    };
  
    window.addEventListener('popstate', updateView);
    updateView();
  
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1280);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('popstate', updateView);
      window.removeEventListener('resize', handleResize);
    };
  }, [currentUser]);

  

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const planDetailsRef = doc(userDocRef, "subscriptionData", "planDetails");

        // Fetch from localStorage initially
        const localCurrentPlan = localStorage.getItem(`currentPlan_${user.uid}`);
        if (localCurrentPlan) {
            // console.log("Loaded current plan from localStorage:", localCurrentPlan);
            setCurrentPlan(localCurrentPlan);
        } else {
            // Fetch from Firestore if not in localStorage
            const fetchPlanDetails = async () => {
                // console.log("Fetching current plan from Firestore");
                const docSnap = await getDoc(planDetailsRef);
                if (docSnap.exists()) {
                    const currentPlan = docSnap.data().currentPlan;
                    setCurrentPlan(currentPlan);
                    localStorage.setItem(`currentPlan_${user.uid}`, currentPlan);
                    // console.log("Loaded current plan from Firestore:", currentPlan);
                } else {
                    // console.log("No plan details document in Firestore!");
                }
            };

            fetchPlanDetails();
        }

        // Real-time listener to update state and localStorage on change
        const unsubscribe = onSnapshot(planDetailsRef, (docSnap) => {
            if (docSnap.exists()) {
                const newCurrentPlan = docSnap.data().currentPlan;
                const storedCurrentPlan = localStorage.getItem(`currentPlan_${user.uid}`);

                if (!storedCurrentPlan || storedCurrentPlan !== newCurrentPlan) {
                    setCurrentPlan(newCurrentPlan);
                    localStorage.setItem(`currentPlan_${user.uid}`, newCurrentPlan);
                    // console.log("Updated current plan from Firestore:", newCurrentPlan);
                }
            } else {
                // console.log("No plan details document in Firestore!");
            }
        });

        return () => {
            unsubscribe();
            // console.log("Unsubscribed from plan details updates");
        };
    }
}, [currentUser]);


          
          

  // If the screen is narrow, render the CloseSidebar component instead
  if (isNarrowScreen) {
    return <CloseSidebar />;
  }


  const isCurrentView = (view) => currentView === view;

  const getLinkClass = (view) => {
    return `sidebar-link ${isCurrentView(view) ? 'active' : ''}`;
  };

  const getIconClass = (view) => {
    return `link-icon ${isCurrentView(view) ? 'active-icon' : ''}`;
};

  // const timeUsed = 10; // Example value
  // const timeUsedPercentage = totalTime !== "Loading..." ? (timeUsed / totalTime) * 100 : 0;


  return (
    <div className="sidebar">
      <Link to="/bumper" className="bumpupload-button">
        <div className="bumpupload-content">
          <img src={bumpUPlogo} alt="Bump Up Logo" className="link-icon-bump" />
          <div className="bumpbutton-text">BUMP</div>
        </div>
      </Link>
        <div className="dividersides"></div>
        <Link to="/dashboard" className={getLinkClass('/dashboard')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={dashboardLogo} alt="Dashboard" className={getIconClass('/dashboard')} />
            Dashboard
        </Link>
        <div className="dividersides"></div>
        <Link to="/creator" className={getLinkClass('/creator')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={creatoricon} alt="Creator" className={getIconClass('/creator')} />
            Creator
        </Link>
        <Link to="/links" className={getLinkClass('/links')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={linkIcon} alt="Links" className={getIconClass('/links')} />
            Links
        </Link>
        <a href="https://intercom.help/bumpups/en/" target="_blank" rel="noopener noreferrer" className={getLinkClass('/help')}>
            <img src={helpIcon} alt="Help" className={getIconClass('/help')} />
            Help
        </a>
        <div className="plan-section">
        <div className="section-title">
                <img src={subicon} alt={currentPlan} className="section-icon" />
                {currentPlan === "bump" ? "Bump Plan" : (currentPlan === "Essential Plan" || currentPlan === "base") ? "Base Plan" : currentPlan}
            </div>

            <div className="plan-status">
                <Link to="/plans" className="manage-plan">
                    Manage plan
                </Link>
            </div>
        </div>
    </div>
);


}

export default Sidebar;
