import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './CloseSidebar.css';

//IMAGES
import selectedIcon from '../assets/images/v2-pricing.png';
import helpIcon from '../assets/images/v2-helpicon.png';
import dashboardLogo from '../assets/images/dashboard-logo-v6.png';
import creatoricon from '../assets/images/v2-creator.png';
import subicon from '../assets/images/v2-plan.png';
import linkIcon from '../assets/images/link-v3.png';
import bumpUPlogo from '../assets/images/bumparrow.png';


function CloseSidebar() {
    // State to track the current view
    const [currentView, setCurrentView] = useState('');

    // Effect to update the state based on the current window location
    useEffect(() => {
        // Function to update the current view based on the window's pathname
        const updateView = () => {
            setCurrentView(window.location.pathname);
        };

        // Add event listener for changes in the URL
        window.addEventListener('popstate', updateView);

        // Call the updateView function to set the initial state
        updateView();

        // Cleanup event listener
        return () => window.removeEventListener('popstate', updateView);
    }, []);

       // Helper function to get the appropriate icon class
       const getLinkClass = (view) => {
        return `close-sidebar-link ${isCurrentView(view) ? 'close-link-selected' : ''}`;
    };
    
    const getIconClass = (view) => {
        return `close-link-icon ${isCurrentView(view) ? 'close-link-icon-selected' : ''}`;
    };
    
    // Helper function to determine if the link is the current view
    const isCurrentView = (view) => currentView === view;



    return (
        <div className="close-sidebar">
                <div className="close-create-wrapper">
                <Link to="/bumper" className="close-create-button" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={bumpUPlogo} alt="Bump Up Logo" className="link-icon-bump-close" />
                </Link>
                </div>
            <div className="close-dividersides"></div>
            <Link to="/dashboard" className={getLinkClass('/dashboard')}>
            <img src={dashboardLogo} alt="Dashboard" className={getIconClass('/dashboard')} />
            </Link>

            <Link to="/creator" className={getLinkClass('/creator')}>
                <img src={creatoricon} alt="Creator" className={getIconClass('/creator')} />
            </Link>

            <Link to="/links" className={getLinkClass('/links')}>
                <img src={linkIcon} alt="Links" className={getIconClass('/links')} />
            </Link>

            <a href="https://intercom.help/bumpups/en/" target="_blank" rel="noopener noreferrer" className={getLinkClass('/help')}>
                <img src={isCurrentView('/help') ? selectedIcon : helpIcon} alt="Help" className={getIconClass('/help')} />
            </a>

            <Link to="/plans" className={getLinkClass('/plans')}>
                <img src={subicon} alt="Plans" className={getIconClass('/plans')} />
            </Link>

        </div>
    );
}

export default CloseSidebar;