import React, { useState, useEffect, useCallback } from 'react';
import './CreatorCalendar.css';

//FIREBASE
import { doc, updateDoc, deleteField } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

//FUNCTIONS
import axios from 'axios';
import he from 'he';

//IMAGES
import deletecontentLogo from '../assets/images/delete-logo.png';
import analyticsIcon from '../assets/images/anayltics-icon.png';


const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


const CreatorCalendar = ({searchvideo, setSearchVideo, selectedDate, setSelectedDate, accessToken, setAccessToken, channelId, setChannelId }) => {

  const [animatingMonth, setAnimatingMonth] = useState(null);
  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();


  // Retrieve initial values from sessionStorage or default to current values
  const initialYear = parseInt(sessionStorage.getItem('selectedYear'), 10) || currentYear;
  const initialMonth = sessionStorage.getItem('selectedMonth') || months[currentMonthIndex];

  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);


  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS



  useEffect(() => {
    // Update sessionStorage whenever selectedYear, selectedMonth, or selectedDate changes
    sessionStorage.setItem('selectedYear', selectedYear.toString());
    sessionStorage.setItem('selectedMonth', selectedMonth);
    
    // Ensure the selected date is not in the future and update sessionStorage
    const updatedDate = new Date(selectedDate) > new Date(getCurrentMonth()) ? getCurrentMonth() : selectedDate;

    // console.log('Updated selectedDate [CREATOR CALENDAR]:', updatedDate);

    setSelectedDate(updatedDate); // This might trigger the effect again, but won't cause an infinite loop as the condition prevents further updates
    sessionStorage.setItem('ISOselectedDate', updatedDate);

  }, [selectedYear, selectedMonth, selectedDate, setSelectedDate]); // This effect depends on all three

  // Function to get the current month in the required format
  function getCurrentMonth() {
    const current = new Date();
    return current.toISOString().slice(0, 7); // Format: YYYY-MM
  }




  const [pendingUpdate, setPendingUpdate] = useState(null);

  const incrementYear = () => {
    if (selectedYear < currentYear) {
      setSelectedYear((prevYear) => {
        const newYear = prevYear + 1;
  
        if (!(newYear > currentYear || (newYear === currentYear && months.indexOf(selectedMonth) > currentMonthIndex))) {
          setPendingUpdate({ month: selectedMonth, year: newYear });
        } else {
          setSelectedMonth('Jan');
          setPendingUpdate({ month: 'Jan', year: newYear });
        }
  
        return newYear;
      });
    }
  };

  const decrementYear = () => {
    setSelectedYear((prevYear) => {
      const newYear = prevYear - 1;
      setPendingUpdate({ month: selectedMonth, year: newYear });
      return newYear;
    });
  };

  const handleMonthClick = useCallback((month, year = selectedYear) => {
    const monthIndex = months.indexOf(month) + 1;
    const formattedMonth = monthIndex.toString().padStart(2, '0');
  
    setSelectedMonth(month);
    setAnimatingMonth(month);
  
    const formattedDate = `${year}-${formattedMonth}`;
    setSelectedDate(formattedDate);
  
    setTimeout(() => setAnimatingMonth(null), 500);
  }, [selectedYear, setSelectedDate]); // Add any dependencies here
  

  

  useEffect(() => {
    if (pendingUpdate) {
      handleMonthClick(pendingUpdate.month, pendingUpdate.year);
      setPendingUpdate(null); // Reset pending update
    }
  }, [pendingUpdate, handleMonthClick]); // Dependency on pendingUpdate

 //REALOAD CATALOG FUNCTION
 //REALOAD CATALOG FUNCTION
 //REALOAD CATALOG FUNCTION
 //REALOAD CATALOG FUNCTION


const [isProcessing,setIsProcessing]=useState(false);
const [isCooldown,setIsCooldown]=useState(false);

const handleDeleteArray = async (selectedDate, channelId) => {
  setIsProcessing(true); // Immediately disable the button on click

  try {
    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);
    const videoCacheRef = doc(userDocRef, "contentCatalog", "youtubeCatalog", "userChannels", channelId);

    await updateDoc(videoCacheRef, {
      [selectedDate]: deleteField()
    });

    const localStorageKey = `ISOfinalvideoCache_${user.uid}_${channelId}_${selectedDate}`;
    localStorage.removeItem(localStorageKey);
  } catch (error) {
    // console.error('Error deleting array for selected date:', error);
  } finally {
    // Initiate cooldown
    setIsCooldown(true);
    window.location.reload();
    localStorage.setItem('cooldownStart', Date.now().toString());
    setTimeout(() => {
      setIsCooldown(false);
      localStorage.removeItem('cooldownStart');
      setIsProcessing(false); // Re-enable the button after cooldown
    }, 10000); // Cooldown period of 10 seconds
  }
};


// Effect hook to manage cooldown state based on localStorage data
useEffect(() => {
  const cooldownStart = localStorage.getItem('cooldownStart');
  if (cooldownStart) {
    const timeElapsed = Date.now() - new Date(parseInt(cooldownStart)).getTime();
    if (timeElapsed < 10000) { // If within the 10 seconds cooldown period
      setIsCooldown(true);
      const cooldownTimeout = setTimeout(() => {
        setIsCooldown(false);
        localStorage.removeItem('cooldownStart');
      }, 10000 - timeElapsed); // Set timeout for the remaining cooldown time

      return () => clearTimeout(cooldownTimeout); // Clear timeout on component unmount
    } else {
      localStorage.removeItem('cooldownStart'); // Clear expired cooldown start time
    }
  }
}, []);


const [isContentAvailable, setIsContentAvailable] = useState(false);
const [noVideosMade, setnoVideosMade] = useState(false);


useEffect(() => {
  const checkDataAvailability = async () => {
    const channelIdToUse = channelId || 'default_value';
    const user = auth.currentUser;

    const localStorageKey = `ISOfinalvideoCache_${user.uid}_${channelIdToUse}_${selectedDate}`;
    const cachedVideos = localStorage.getItem(localStorageKey);

        // Check if the cachedVideos string is exactly "[]" indicating an empty array
        if (cachedVideos === "[]") {
          // console.log("No videos were made for the selected period.");
          setnoVideosMade(true); // Indicate no videos were made
          setIsContentAvailable(false); // Content is available
        } else {
          setnoVideosMade(false); // There are videos available
        }


    if (cachedVideos && cachedVideos !== "[]") {
      setIsContentAvailable(true); // Content is available
      setnoVideosMade(false);
    } else {
      setIsContentAvailable(false);
      setnoVideosMade(true); // Assume no videos were made if nothing is in localStorage
    }
  };

  checkDataAvailability();
}, [selectedDate, channelId, setIsContentAvailable, setnoVideosMade]);






 //SEARCH FUNCTION
 //SEARCH FUNCTION
 //SEARCH FUNCTION
 //SEARCH FUNCTION

 const convertDurationToSeconds = (duration) => {
  if (!duration) return 0; // Return 0 if duration is null or undefined

  let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  let hours = (parseInt(match[1]) || 0);
  let minutes = (parseInt(match[2]) || 0);
  let seconds = (parseInt(match[3]) || 0);
  return hours * 3600 + minutes * 60 + seconds;
};


const [searchVideoId, setSearchVideoId] = useState('');


const [isSearchError, setIsSearchError] = useState(false); // State to control button color on error
const [searchbuttonText, setsearchbuttonText] = useState("Search");


const fetchVideoById = async () => {
  if (!searchVideoId) return;

  let videoId;
  if (searchVideoId.includes("youtube.com")) {
    const url = new URL(searchVideoId);
    videoId = url.searchParams.get("v");
  } else {
    videoId = searchVideoId;
  }

  const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
  const videoResponse = await axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet,contentDetails&key=${apiKey}&channelId=${channelId}`);

  const videoItem = videoResponse.data.items[0];

  if (videoItem && videoItem.snippet.channelId !== channelId) {
    setsearchbuttonText("Use Links Page To Process");
    setIsSearchError(true);
    setTimeout(() => {
      setIsSearchError(false);
      setsearchbuttonText("Search");
    }, 3000);
    return;
  }

  if (videoResponse.data.items.length > 0) {
    setIsSearchError(false);

    const duration = videoItem.contentDetails.duration;
    // Assume `convertDurationToSeconds` is defined elsewhere
    const durationInSeconds = convertDurationToSeconds(duration);

    if (durationInSeconds >= 240 && durationInSeconds <= 12600) {
      const videoTitle = he.decode(videoItem.snippet.title); // Assume `he` is defined/imported
      const truncatedTitle = videoTitle.length > 52 ? videoTitle.substring(0, 52) + "..." : videoTitle;
      const thumbnailUrl = videoItem.snippet.thumbnails.high.url;

      setSearchVideo([{ thumbnailUrl, videoId, durationInSeconds, title: truncatedTitle }]);
      setsearchbuttonText("Search");
    } else {
      // Check if the video duration is too short
      if (durationInSeconds < 240) {
        setsearchbuttonText("Duration Under 4m");
      } else {
        // Otherwise, it must be too long
        setsearchbuttonText("Duration Over 3.5h");
      }
      setIsSearchError(true);
      setTimeout(() => {
        setIsSearchError(false);
        setsearchbuttonText("Search");
      }, 3000);
    }
  } else {
    setsearchbuttonText("Could Not Find Video");
    setIsSearchError(true);
    setTimeout(() => {
      setIsSearchError(false);
      setsearchbuttonText("Search");
    }, 3000);
  }
};



const [currentDate, setCurrentDate] = useState({ year: null, month: null });

useEffect(() => {
  const today = new Date();
  setCurrentDate({ year: today.getFullYear(), month: today.getMonth() }); // Store both year and month
}, []);

// Convert selectedDate string to Date object to extract the year and month
const getDateComponentsFromDateStr = (dateStr) => {
  const [year, month] = dateStr.split('-').map(Number); // Convert year and month to numbers
  return { year, month: month - 1 }; // Month is zero-based in Date, so subtract 1
};

// Logic to determine if the delete button should be disabled
const selectedDateComponents = getDateComponentsFromDateStr(selectedDate);
const isDeleteDisabled = !(selectedDateComponents.year === currentDate.year && selectedDateComponents.month === currentDate.month) && (isCooldown || !isContentAvailable || noVideosMade);


const [isUpdateProcessing, setIsUpdateProcessing] = useState(false);
const [isUpdateCooldown, setIsUpdateCooldown] = useState(false);

const functions = getFunctions();
const refreshVLCStats = httpsCallable(functions, 'refreshVLCStats');

const handleReloadClick = async (setProcessing, setCooldown) => {
  try {
    await refreshVLCStats();
    // Handle result if needed
  } catch (error) {
    // Handle error if needed
  } finally {
    setProcessing(false);
    setCooldown(true);
    setTimeout(() => setCooldown(false), 5000); // Example cooldown period
  }
};






return (
  <div className="creator-calendar-container">
   <div className="creator-ALL-content">
    <div className="creator-year-and-search-wrapper">
    <div className="creator-year-and-delete-container">
      <div className="creator-year-selector">
        <button className="creator-year-button" onClick={decrementYear}>-</button>
        <span className="creator-year-display"><b>{selectedYear}</b></span>
        <button className="creator-year-button" onClick={incrementYear} disabled={selectedYear >= currentYear}>+</button>
      </div>

      {accessToken && (
      <div className={`creator-buttons ${!isDeleteDisabled ? '' : 'disabled-content'}`} style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="button-row" style={{ display: 'flex', gap: '10px' }}>

        <button
          className="delete-array-button"
          data-hover-text="Reload Current Month"
          onClick={() => {
            if (!isCooldown) {
              setIsProcessing(true);
              handleDeleteArray(selectedDate, channelId);
            }
          }}
          style={{ 
            backgroundColor: isProcessing ? '#ff4747' : (isCooldown ? 'rgba(211, 211, 211, 0.3)' : ''),
            cursor: isCooldown ? 'not-allowed' : 'pointer',
            position: 'relative'  // Ensure the button has a positioning contexts
          }}
          disabled={isCooldown}
        >
          <img 
            src={deletecontentLogo} 
            alt={isProcessing ? "Deleting" : "Delete"}
          />
          <span className="reload-catalog-text">
            {isCooldown ? "Success" : (isProcessing ? "Reloading" : "Reload")}
          </span>          
        </button>


        <button
          className="update-array-button"
          data-hover-text="Get Latest Stats"
          onClick={() => {
            if (!isUpdateCooldown) {
              setIsUpdateProcessing(true);
              handleReloadClick(setIsUpdateProcessing, setIsUpdateCooldown);
            }
          }}
          style={{ 
            backgroundColor: isUpdateProcessing ? 'rgba(74, 221, 128, 0.4)' : (isUpdateCooldown ? 'rgba(211, 211, 211, 0.3)' : ''),
            cursor: isUpdateCooldown || isUpdateProcessing ? 'not-allowed' : 'pointer',
            position: 'relative'  // Ensure the button has a positioning context
          }}
          disabled={isUpdateCooldown || isUpdateProcessing}
        >
          <img 
            src={analyticsIcon} 
            alt={isUpdateProcessing ? "Updating" : "Update"}
          />
          <span className="reload-catalog-text">
            {isUpdateCooldown ? "Success" : (isUpdateProcessing ? "Updating" : "Update")}
          </span>          
        </button>





        </div>
      </div>
    )}
    </div>



        <div className={`creator-youtube-search ${!accessToken && 'disabled-content'}`}>
              <div className="creator-search-bar-wrapper">
                <input
                  className="creator-search-input"
                  type="text"
                  placeholder="Enter Youtube Link"
                  value={searchVideoId}
                  onChange={(e) => setSearchVideoId(e.target.value)}
                  disabled={!accessToken}
                />
                <button
                  className={`creator-search-button ${isSearchError && 'search-error'}`}
                  onClick={fetchVideoById}
                  disabled={!accessToken}
                >
                  {searchbuttonText}
                </button>
              </div>
            </div>



    </div>

    <div className="creator-month-tabs">
      {months.map((month, index) => {
        // Determine if the month is in the future
        const isFutureMonth = selectedYear > currentYear || (selectedYear === currentYear && index > currentMonthIndex);

        return (
          <button
            key={index}
            className={`creator-month-tab ${month === selectedMonth ? 'active' : ''} ${month === animatingMonth ? 'animating' : ''}`}
            onClick={() => !isFutureMonth && handleMonthClick(month)}
            disabled={isFutureMonth}
            style={{ opacity: isFutureMonth ? 0.5 : 1 }}
          >
            {month}
          </button>
        );
      })}
    </div>
    </div>
  </div>
);


};

export default CreatorCalendar;
