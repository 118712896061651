import React, { useState, useEffect } from 'react';
import './App.css';

//FUNCTIONS
import { AuthProvider } from './AuthContext';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import "@stripe/stripe-js"

//IMPORT UI
import HomePage from './unAuthPages/HomePage';
import PricingPage from './unAuthPages/PricingPage';
import GeneralDashboard from './myDashboard/generalDashboard';
import BumpupDashboard from './myBumpUp/bumperComponents/bumpupDashboard';
import ViewDoneBump from './myBumpUp/doneBumpComponents/viewDoneBump';
import Dashboard from './myCreator/Dashboard';
import NonCreatorDashboard from './myNonCreator/nonCreatorDashboard';
import VideoValuePage from './myVideoPage/VideoValuePage';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import SignUpPage from './unAuthPages/SignUpPage';
import SignInPage from './unAuthPages/SignInPage';
import Aboutus from './unAuthPages/Aboutus';
import UserSettings from './mySettings/UserSettings';
import SubscriptionPage from './myPlans/SubscriptionPage';
import TermsOfService from './unAuthPages/TermsOfService';
import PrivacyPolicy from './unAuthPages/PrivacyPolicy';


function App() {

   // Read the initial state from localStorage, or default to true
   const [isSidebarOpen, setIsSidebarOpen] = useState(
    localStorage.getItem('isSidebarOpen') === 'true'
  );

  useEffect(() => {
    // Save to localStorage whenever isSidebarOpen changes
    localStorage.setItem('isSidebarOpen', isSidebarOpen);
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(prevIsSidebarOpen => {
      // Calculate the new state value and save it to localStorage
      const newState = !prevIsSidebarOpen;
      localStorage.setItem('isSidebarOpen', newState);
      return newState; // Return the new state value
    });
  };

  return (
    <AuthProvider>
      <Router>
        <div className="App">
        <Navbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/dashboard" element={<PrivateRoute><GeneralDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />
            <Route path="/bumper" element={<PrivateRoute><BumpupDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />
            <Route path="/bumper/:videoId" element={<PrivateRoute><ViewDoneBump isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />
            <Route path="/creator" element={<PrivateRoute><Dashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/plans" element={<PrivateRoute>< SubscriptionPage isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/settings/*" element={<PrivateRoute><UserSettings isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/links" element={<PrivateRoute><NonCreatorDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/video/:videoId" element={<PrivateRoute><VideoValuePage isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} /> 


            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />

          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
