import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth } from '../firebase';





export const FetchCreatorVideos = async ({
    channelId = 'default_value', 
    getCurrentUser, 
    setIsLoading, 
    setProgress, 
    selectedDate, 
    setVideos, 
    setIsContentAvailable,
    setnoVideosMade
  }) => {
    
    // console.log("Starting to fetch creator videos from the backend...");
    const functions = getFunctions();
    const channelIdToUse = channelId || 'default_value';
    const user = auth.currentUser;
  
  
    // console.log(`Using channel ID: ${channelIdToUse}, for user: ${user.uid}`);
    setIsLoading(true);
    setProgress(30); // Set initial progress to 10
  
    // Set up a timer to increment progress every 3 seconds
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        // If progress reaches 90 or above, stop incrementing to reserve space for final completion
        if (prevProgress >= 90) {
          clearInterval(progressInterval);
          return prevProgress;
        }
        return prevProgress + 10; // Increment progress by 10
      });
    }, 2000); // 3000ms = 3 seconds
  
    try {

        // Usage in your existing function
        const requestData = {
          "requestMonth": selectedDate,
          "requestType": "youtube"
        };
  
    //   console.log("Sending data to backend:", requestData);
  
      // Call the backend function
      // console.log("Calling backend function 'getContentCatalog'...");
      const getContentCatalog = httpsCallable(functions, 'getContentCatalog');
      const response = await getContentCatalog(requestData);
      const { results: newVideos } = response.data;
  

          // Check if newVideos is an empty array
      if (newVideos.length === 0) {
        setnoVideosMade(true); // Set noVideosMade to true if the array is empty
      } else {
        setnoVideosMade(false); // Set noVideosMade to false if the array is not empty
      }

      // console.log("Received data from backend:", newVideos);
  
      // Update local state with the received videos
    //   console.log("Updating local state with new videos...");
      setVideos(newVideos);
      setIsContentAvailable(true); // Indicate that content is available
  
      // Cache the new videos in local storage
      const localStorageKey = `ISOfinalvideoCache_${user.uid}_${channelIdToUse}_${selectedDate}`;
    //   console.log("Caching new videos in localStorage with key:", localStorageKey);
      localStorage.setItem(localStorageKey, JSON.stringify(newVideos));
  
      setProgress(100); // Set to 100% once videos are loaded and state is updated
      clearInterval(progressInterval); // Clear the progress interval
    } catch (error) {
      // console.error("Failed to fetch videos from backend:", error);
      setProgress(0); // Reset progress in case of error
      setIsContentAvailable(false); // Indicate that no content is available due to the error
      clearInterval(progressInterval); // Ensure the interval is cleared on error as well
    } finally {
      setIsLoading(false);
    }
  };