import React, { useState } from 'react';
import './BrandTemplate.css'; 




const SubtitleTemplate = () => {
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [languageOne, setLanguageOne] = useState('');
    const [languageTwo, setLanguageTwo] = useState('');

    const languages = [
        { code: 'en', name: 'English' },
        { code: 'es', name: 'Spanish' },
        { code: 'pt', name: 'Portuguese' },
        { code: 'fr', name: 'French' },
        { code: 'de', name: 'German' },
        { code: 'ja', name: 'Japanese' }
    ];

    const filteredLanguages = languages.filter(lang => lang.code !== defaultLanguage);

    return (
            <div className="brand-template-container">
                <h2>Creators Default Language</h2>
                <select className="custom-dropdown" value={defaultLanguage} onChange={e => setDefaultLanguage(e.target.value)}>
                    <option value="">Select Default Language</option>
                    {languages.map(lang => (
                        <option key={lang.code} value={lang.code}>{lang.name}</option>
                    ))}
                </select>

                <h2>Select two languages to auto caption</h2>
                <div className="dropdown-container">
                    <select className="custom-dropdown" value={languageOne} onChange={e => setLanguageOne(e.target.value)}>
                        <option value="">Select Language</option>
                        {filteredLanguages.map(lang => (
                            <option key={lang.code} value={lang.code}>{lang.name}</option>
                        ))}
                    </select>

                    <select className="custom-dropdown" value={languageTwo} onChange={e => setLanguageTwo(e.target.value)}>
                        <option value="">Select Language</option>
                        {filteredLanguages.map(lang => (
                            <option key={lang.code} value={lang.code}>{lang.name}</option>
                        ))}
                    </select>
                </div>
                <p>In the future, we will expand the capabilities of this feature to include more languages and options.</p>
            </div>

    );
};

export default SubtitleTemplate;