import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import './SubscriptionPage.css';

//FIREBASE
import { db, auth } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';

//FUNCTIONS
import AppTransition from '../components/AppTransition';

//IMPORT UI
import Sidebar from '../myDashboard/Sidebar';
import CloseSidebar from '../myDashboard/CloseSidebar';
import PlanPopUp from './PlanPopUp';
import PaidUserPlanPopUp from './PaidUserPlanPopUp';

//IMAGES
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';

const SubscriptionPage = ({ isSidebarOpen, toggleSidebar }) => {

  // Helper function to safely convert Firebase timestamp to JavaScript Date
  const toDate = (timestamp) => {
    if (timestamp?.toDate) {
      return timestamp.toDate(); 
    } else if (typeof timestamp === 'number') {
      return new Date(timestamp * 1000); 
    } else {
      return null; 
    }
  };

  const formatDate = useCallback((timestamp) => {
    const dateObject = toDate(timestamp);
    if (!dateObject) return null; 
    
    return dateObject.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }, []);

  const user = auth.currentUser;

  // Function to get initial values from sessionStorage or set default values
  const getInitialValues = () => {
    if (user) {
      const sessionData = sessionStorage.getItem(`userData_${user.uid}`);
      if (sessionData) {
        const data = JSON.parse(sessionData);

        let formattedRenewalDate = null;
        if (data.RenewalDate) {
          const renewalDateObject = new Date(data.RenewalDate.seconds * 1000);
          formattedRenewalDate = renewalDateObject.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          });
        }
        return {
          isActive: data.isActive,
          currentPlan: data.currentPlan,
          totalPlanTime: data.totalPlanTime,
          price: data.price,
          formattedRenewalDate: formattedRenewalDate,
          billingEmail: data.billingEmail // Initialize billingEmail
        };
      }
    }
    return { isActive: true, currentPlan: "Loading...", totalPlanTime: null, price: 0, formattedRenewalDate: null, billingEmail: null };
  };

  const initial = getInitialValues();

  // Initialize state with values from sessionStorage or default values
  const [isActive, setIsActive] = useState(initial.isActive);
  const [currentPlan, setCurrentPlan] = useState(initial.currentPlan);
  const [timeBank, setTimeBank] = useState(0); 
  const [timeBankBump, setTimeBankBump] = useState(0); 
  const [totalPlanTime, setTotalPlanTime] = useState(initial.totalPlanTime);
  const [price, setPrice] = useState(initial.price);
  const [renewalDate, setRenewalDate] = useState(initial.formattedRenewalDate);
  const [billingEmail, setBillingEmail] = useState(initial.billingEmail); // Add billingEmail state
  const [showPlanPopUp, setShowPlanPopUp] = useState(false);

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const planDetailsRef = doc(userDocRef, "subscriptionData", "planDetails");
      const timeWardenRef = doc(userDocRef, "subscriptionData", "timeWarden");

      const sessionUserData = sessionStorage.getItem(`userData_${user.uid}`);
      if (sessionUserData) {
        const { isActive, currentPlan, totalPlanTime, price, RenewalDate, billingEmail } = JSON.parse(sessionUserData); // Add billingEmail
        setIsActive(isActive);
        setCurrentPlan(currentPlan);
        setTotalPlanTime(totalPlanTime);
        setPrice(price);
        setRenewalDate(formatDate(RenewalDate));
        setBillingEmail(billingEmail); // Set billingEmail from session data
      }

      const unsubscribePlanDetails = onSnapshot(planDetailsRef, (doc) => {
        if (doc.exists()) {
          const newPlanData = doc.data();
          setIsActive(newPlanData.IsActive);
          setCurrentPlan(newPlanData.currentPlan);
          setTotalPlanTime(newPlanData.TotalPlanTime);
          setPrice(newPlanData.Price);
          setRenewalDate(formatDate(newPlanData.RenewalDate));
          setBillingEmail(newPlanData.StripeCustomerEmail || null); // Set billingEmail from Firestore

          const userData = {
            isActive: newPlanData.IsActive,
            currentPlan: newPlanData.currentPlan,
            totalPlanTime: newPlanData.TotalPlanTime,
            price: newPlanData.Price,
            RenewalDate: newPlanData.RenewalDate,
            billingEmail: newPlanData.StripeCustomerEmail || null // Store billingEmail in session
          };
          sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify(userData));
        }
      });

      const unsubscribeTimeBank = onSnapshot(timeWardenRef, (doc) => {
        if (doc.exists() && doc.data().activeTimes) {
          setTimeBank(doc.data().timeBank);
          setTimeBankBump(doc.data().timeBankBump);
        }
      });

      const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
      if (sessionTimeBank) {
        setTimeBank(JSON.parse(sessionTimeBank));
      }

      const sessionTimeBankBump = sessionStorage.getItem(`timeBankBump_${user.uid}`);
      if (sessionTimeBankBump) {
        setTimeBankBump(JSON.parse(sessionTimeBankBump));
      }

      return () => {
        unsubscribePlanDetails();
        unsubscribeTimeBank();
      };
    }
  }, [user, formatDate]);

  const openContactPage = () => {
    window.open("https://bumpups.zapier.app/get-in-touch", "_blank");
  };

  const openStripeBilling = () => {
    window.open("https://billing.stripe.com/p/login/9AQcNCf6w0SS6C4000", "_blank");
  };

  return (
    <div className="metasub-container">
      {isSidebarOpen && <Sidebar />}
      {!isSidebarOpen && <CloseSidebar />}
      <AppTransition>
        <div className="subscription-page-container">
          <Helmet>
            <title>Bumpups</title>
            <meta name="description" content="Manage your Bumpups subscription, billing, and plan details. Upgrade or change your plan easily." />
          </Helmet>
  
          <div className="subscription-plan-box">
            <div className={`active-badge ${isActive ? "" : "active-badge-canceled"}`}>
              {isActive !== null ? (isActive ? "Active" : "Canceled") : "-----"}
            </div>
  
            <h2 className="plan-title">
              {(currentPlan === "base" || currentPlan === "bump" || currentPlan === "Essential Plan") && (
                <img 
                  src={currentPlan === "base" || currentPlan === "Essential Plan" ? basePlanLogo : bumpPlanLogo} 
                  alt={currentPlan === "base" || currentPlan === "Essential Plan" ? "Base Plan Logo" : "Bump Plan Logo"} 
                  className="bumpplan-logo" 
                />
              )}
              {currentPlan === "base" || currentPlan === "Essential Plan" ? "Base Plan" : currentPlan === "bump" ? "Bump Plan" : currentPlan || "Loading..."}
            </h2>


            <div className="plan-details">
  <div className="upload-time-container">
    {currentPlan === "Free Plan" && (
      <div className="upload-time-wrapper">
        <span className="upload-credits-label">Free credits</span>
        <span className="upload-credits-time" style={{ color: '#ffbf00' }}>
          {timeBank ? `${Math.floor(timeBank / 60)}h ${timeBank % 60}m` : "0m"}
        </span>
      </div>
    )}

    {(currentPlan === "base" || currentPlan === "bump" || currentPlan === "Essential Plan") && (
      <>
        {(currentPlan === "base" || currentPlan === "Essential Plan") ? (
          <>
            <div className="upload-time-wrapper">
              <div className="upload-credits-label-with-logo">
                <img src={basePlanLogo} alt="Base Plan Logo" className="plan-logo" />
                <span className="upload-credits-label">Base credits</span>
              </div>
              <div className="upload-credits-time-with-icon">
                <span className="upload-credits-time base-plan-time">
                  {timeBank ? (
                    <>
                      <span className="time-value">{`${Math.floor(timeBank / 60)}h ${timeBank % 60}m`}</span>
                    </>
                  ) : "0m"}
                </span>
              </div>
            </div>

            <div className="upload-time-wrapper">
              <div className="upload-credits-label-with-logo">
                <img src={bumpPlanLogo} alt="Bump Plan Logo" className="plan-logo" />
                <span className="upload-credits-label">Bump credits</span>
              </div>
              <div className="upload-credits-time-with-icon">
                <span className="upload-credits-time bump-plan-time">
                  {timeBankBump ? (
                    <>
                      <span className="time-value">{`${Math.floor(timeBankBump / 60)}h ${timeBankBump % 60}m`}</span>
                    </>
                  ) : <span style={{ color: '#4add80' }}>0h 0m</span>}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="upload-time-wrapper">
              <div className="upload-credits-label-with-logo">
                <img src={bumpPlanLogo} alt="Bump Plan Logo" className="plan-logo" />
                <span className="upload-credits-label">Bump credits</span>
              </div>
              <div className="upload-credits-time-with-icon">
                <span className="upload-credits-time bump-plan-time">
                  {timeBankBump ? (
                    <>
                      <span className="time-value">{`${Math.floor(timeBankBump / 60)}h ${timeBankBump % 60}m`}</span>
                    </>
                  ) : <span style={{ color: '#4add80' }}>0h 0m</span>}
                </span>
              </div>
            </div>

            <div className="upload-time-wrapper">
              <div className="upload-credits-label-with-logo">
                <img src={basePlanLogo} alt="Base Plan Logo" className="plan-logo" />
                <span className="upload-credits-label">Base credits</span>
              </div>
              <div className="upload-credits-time-with-icon">
                <span className="upload-credits-time base-plan-time">
                  {timeBank ? (
                    <>
                      <span className="time-value">{`${Math.floor(timeBank / 60)}h ${timeBank % 60}m`}</span>
                    </>
                  ) : "0m"}
                </span>
              </div>
            </div>
          </>
        )}
      </>
    )}
  </div>
</div>

              <p className="rebuy-text rebuy-text-spacing">
                Running out of upload credits earlier? Rebuy a new plan and unused credits will carry over.
              </p>
              <button
                className="add-more-btn"
                onClick={() => setShowPlanPopUp(true)}
              >
                  {(currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") && !isActive ? 
                    "Renew Plan" : 
                    (currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") ? 
                    "Add more or change" : 
                    "Upgrade Plan"}
                  </button>
                  {showPlanPopUp && (
                    (currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") && isActive ?
                      <PaidUserPlanPopUp
                        isOpen={showPlanPopUp}
                        onClose={() => setShowPlanPopUp(false)}
                        currentPlan={currentPlan}
                      /> :
                      <PlanPopUp
                        isOpen={showPlanPopUp}
                        onClose={() => setShowPlanPopUp(false)}
                        currentPlan={currentPlan}
                      />
                  )}
          </div>
          <div className="subscription-plan-box billing-box">
            <h2 className="plan-title">Billing & Payment</h2>
            {billingEmail && (
                  <div className="plan-details">
                    <span className="billing-label">Email</span>
                    <span className="billing-info-email ">{billingEmail}</span>
                  </div>
                )}
            <div className="plan-details">
              <span className="billing-label">Price</span>
              <span className="billing-info">
                {price !== null && price !== undefined ? `$${price.toFixed(2)} USD/month` : "Loading..."}
              </span>
            </div>
            {(currentPlan === "base" || currentPlan === "bump" || currentPlan === "Essential Plan") && (
              <>
                <div className="plan-details">
                  <span className="billing-label">Plan Credits</span>
                  <span 
                    className={`billing-info ${currentPlan === "bump" ? "plan-time-bump" : (currentPlan === "base" || currentPlan === "Essential Plan") ? "plan-time-base-essential" : ""}`}
                  >
                    {totalPlanTime || "Loading..."}m
                  </span>
                </div>
                <div className="plan-details">
                  <span className="billing-label">Billing period</span>
                  <span className="billing-info">Monthly</span>
                </div>
                <div className="plan-details">
                  <span className="billing-label">
                    {isActive !== null ? (isActive ? "Renewal date" : "Expiration date") : "Loading..."}
                  </span>
                  <span className={`billing-info ${!isActive ? "red-text" : ""}`}>
                    {renewalDate || "Loading..."}
                  </span>
                </div>
              </>
            )}
            <button className="invoice-update-btn" onClick={openStripeBilling}>
              Invoice & Update billing info
            </button>     
            <button className="invoice-update-btn" onClick={openContactPage}>
              Contact us
            </button>
          </div>
        </div>
      </AppTransition>
    </div>
  );
};

export default SubscriptionPage;
