import React, { useState, useEffect } from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';
import { registerListener } from './ListenerManager';
import bumpupsLogo from '../assets/images/bumpups-dark-small.jpg';

const CreatorAppNotification = () => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationLink, setNotificationLink] = useState(null);

  useEffect(() => {
    const docRef = doc(db, "notifications", "creatorNotification");

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setNotificationMessage(docSnap.data().message);
        setNotificationLink(docSnap.data().link);
      }
    });

    registerListener(unsubscribe);

    return () => {
      unsubscribe();
    };
  }, []);

  if (!notificationMessage) return null;



return (
  <div className="notification-box">
    <img src={bumpupsLogo} alt="Logo" className="notification-logo" />
    <span>
      {notificationMessage}
      {notificationLink && (
        <a href={notificationLink} target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
      )}
    </span>
  </div>
);




};

export default CreatorAppNotification;
