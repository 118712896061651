import { getFunctions, httpsCallable } from 'firebase/functions';

const reprocessBump = async (videoId) => {
    const functions = getFunctions();
    const reprocessCaptionsCallable = httpsCallable(functions, 'reprocessCaptionsBump');

    try {
        const requestData = {
            'videoId': videoId
        };

        // console.log('Payload prepared for reprocessCaptionsBump:', requestData); // Log the payload

        await reprocessCaptionsCallable(requestData);
        // console.log('Response from reprocessCaptionsBump:', response.data);
    } catch (error) {
        // console.error('Error calling reprocessCaptionsBump:', error);
    }
};

export { reprocessBump };
