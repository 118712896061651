import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CreatorInformation.css';

//FIREBASE
import { db, auth } from '../firebase';
import { doc, onSnapshot, collection, setDoc, updateDoc } from 'firebase/firestore';

//FUNCTIONS
import { registerListener } from '../components/ListenerManager';
import { debounce } from 'lodash';
import axios from 'axios';
import { CLIENT_ID, SCOPES } from '../googleOAuth';

//IMAGES
import placeholderProfileImage from '../assets/images/placeholder-load.jpg';
import analyticsIcon from '../assets/images/anayltics-icon.png';
import settingsIcon from '../assets/images/v2-settings.png';
import utubeDisconnect from '../assets/images/utube-dark.png';
import utubeLogoConnected from '../assets/images/utube-red.png';
import commentIcon from '../assets/images/comment-v2.png';



const CreatorInformation = ({accessToken, setAccessToken, channelId, setChannelId}) => {

    const [isConnected, setIsConnected] = useState(false);  // State to hold connection status


  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS

  const [channelDetails, setchannelDetails] = useState({});

  const fetchYoutubeChannelDetails = async (channelId) => {

    const user = auth.currentUser;
    const userId = user ? user.uid : null;

    const sessionChannelInfoStorageKey = `youtubeChannelDetails-${userId}-${channelId}`;

    // Check session storage first
    const storedData = sessionStorage.getItem(sessionChannelInfoStorageKey);
    if (storedData) {
        const {channelDetails, timestamp} = JSON.parse(storedData);

        // Calculate the age of the data in hours
        const dataAgeHours = (Date.now() - timestamp) / (1000 * 60 * 60);
        if (dataAgeHours <= 1) {
            // console.log('We can use this Data');
            // If the data is less than or equal to an hour old, use it
            setchannelDetails(channelDetails);
            return;
        } else {
            // Data is older than an hour, so it will be refreshed below
            // console.log('Data is stale, refreshing channel details...');
        }
    }

    const apiYoutubeKey = process.env.REACT_APP_YOUTUBE_API_KEY;

    try {
      const response = await axios.get(`https://www.googleapis.com/youtube/v3/channels`, {
          params: {
              part: 'snippet,statistics',
              id: channelId,
              key: apiYoutubeKey,
          }
      });
  
      // Log the data received for debugging purposes
      // console.log('YouTube API Response:', response.data);
  
      if (response.data.items.length > 0) {
          const { snippet, statistics } = response.data.items[0];
  
          // Fallback logic for profile image, starting from maxres and moving to lower resolutions
          const profileImage = snippet.thumbnails.maxres?.url ||
                               snippet.thumbnails.high?.url ||
                               snippet.thumbnails.medium?.url ||
                               snippet.thumbnails.default?.url;

                                  // Log the channel details before setting them
          //   console.log('Channel Details:', {
          //     channelName: snippet.title,
          //     subscribers: Number(statistics.subscriberCount).toLocaleString(),
          //     profileImage: profileImage,
          // });
  
          const channelDetails = {
              channelName: snippet.title,
              subscribers: Number(statistics.subscriberCount).toLocaleString(),
              profileImage: profileImage,
          };
  
          setchannelDetails(channelDetails);
  
          // Store channel details along with the current timestamp
          const storagePayload = {
              channelDetails,
              timestamp: Date.now(), // Store the current time as a timestamp
          };
  
          sessionStorage.setItem(sessionChannelInfoStorageKey, JSON.stringify(storagePayload));
      }
  } catch (error) {
      // console.error('Error fetching YouTube channel details:', error);
  }
  
};



  useEffect(() => {
    // Immediately check for accessToken and update connection status
    if (accessToken) {
      // If accessToken is found, consider the user as connected
      setIsConnected(true);
    } else {
      // If accessToken is not found, ensure user is marked as not connected
      setIsConnected(false);

    }

    // Call fetchYoutubeChannelDetails if channelId is available
    if (channelId) {
      fetchYoutubeChannelDetails(channelId).catch(() => {
        // Silent error handling. No action taken on error.
      });
    }

  }, [accessToken, channelId]);



  const [videosProcessed, setvideosProcessed] = useState(0);

  useEffect(() => {
    const user = auth.currentUser;
    const userDocRef = doc(db, 'users', user.uid);
    const valueDataCollectionRef = collection(userDocRef, 'valueData');
  
    const unsubscribe = onSnapshot(valueDataCollectionRef, (querySnapshot) => {
      // Count the documents in the snapshot
      const numberOfVideos = querySnapshot.docs.length;
  
      // Update the state variable with the count
      setvideosProcessed(numberOfVideos);
    });
  
    // Register the unsubscribe function for cleanup
    registerListener(unsubscribe);
  
    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array ensures this effect runs only once on mount
  
  

  const handleConnectYouTube = () => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;

    const connectYoutubeUrl = `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}/connectYoutube`;
  
    if (userId) {
      const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(connectYoutubeUrl)}&scope=${encodeURIComponent(SCOPES)}&response_type=code&access_type=offline&state=${userId}`;
      window.location.href = authUrl;
    } else {
      // console.error("User not authenticated");
    }
  };

   // Optimized Storage
   const user = auth.currentUser;
   let userData = {};

   if (user) {
     const localStorageUserData = localStorage.getItem(`userData_${user.uid}`);
     if (localStorageUserData) {
       const parsedData = JSON.parse(localStorageUserData);
       // Verify the UID matches before using the data
       if (parsedData.uid === user.uid) {
                       // console.log("begin lgiic");

         userData = parsedData;
       }
     }
   }


  const [isToggledcomment, setIsToggledcomment] = useState(userData.isAutoCommentEnabled !== undefined ? userData.isAutoCommentEnabled : true);


  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
  
      // Attempt to load user data from localStorage first and initialize state
      const localStorageUserData = localStorage.getItem(`userData_${user.uid}`);
      const userData = localStorageUserData ? JSON.parse(localStorageUserData) : {};
      setIsToggledcomment(userData.isAutoCommentEnabled !== undefined ? userData.isAutoCommentEnabled : true);
  
      // Real-time listener for creator settings
      const userCreatorSettingsRef = doc(userDocRef, "autoSettings", "youtubeToggles");
      const unsubscribeCreatorSettings = onSnapshot(userCreatorSettingsRef, (creatorSettingsSnap) => {
        if (creatorSettingsSnap.exists()) {
          setIsToggledcomment(creatorSettingsSnap.data().isAutoCommentEnabled);
        } else {
          // Create the field with a default value if it doesn't exist and set state
          setDoc(userCreatorSettingsRef, { isAutoCommentEnabled: true }, { merge: true });
        }
      });
  
      // Cleanup function to unsubscribe from real-time updates when the component unmounts
      return () => {
        unsubscribeCreatorSettings();
      };
    }
  }, []); // Dependencies array is empty, so this effect runs only once on mount
  
  const debouncedCommentHandleToggle = debounce(async (newValue, setIsToggledcomment) => {
    // Optimistically update the UI to reflect the new toggle state
    setIsToggledcomment(newValue);

    // Attempt to update the user's settings in Firestore
    const user = auth.currentUser;
    if (user) {
        const userSettingsRef = doc(db, "users", user.uid, "autoSettings", "youtubeToggles");
        try {
            await updateDoc(userSettingsRef, {
                isAutoCommentEnabled: newValue
            });
        } catch (error) {
            // Log the error to the console and handle it as necessary, possibly by reverting the UI change
            // console.error("Error updating settings: ", error);
        }
    }
}, 500); // 300ms debounce period
  

const handleCommentToggleChange = () => {
  const newValue = !isToggledcomment; // Determine the new value by negating the current state
  debouncedCommentHandleToggle(newValue, setIsToggledcomment); // Call the debounced function with the new value and the state setter function
};
  

  

    return (
        <div className="creator-info-container">

          <div className="creatorinfo-ALL-container">

            <div className="creator-info-box">
            <div className="creator-avatar-section">
            <img 
                    src={channelDetails.profileImage || placeholderProfileImage} 
                    alt="Profile" 
                    className="creator-avatar" 
                />
                <h2 className="channel-title">Your Channel</h2>
                <p className="channel-name">
                {channelDetails.channelName ? channelDetails.channelName : '---------'}
            </p>
            </div>
            <div className="creator-subscription-section">
                <h3 className="subscriber-title">Subscribers</h3>
                <p className="subscriber-count">
                        {channelDetails.subscribers || '-------'}
                    </p>
                    <button
                    className={`creatorpage-connect-button ${isConnected ? 'connected' : ''}`}
                    onClick={() => isConnected ? null : handleConnectYouTube()}
                    style={{ cursor: isConnected ? 'default' : 'pointer' }}
                    >
                    <img
                        src={isConnected ? utubeLogoConnected : utubeDisconnect}
                        alt="YouTube Button"
                        className="create-connectyoutube"
                    />
                    </button>
            </div>
            </div>

                
                <div className="creator-stats-box">
            <div className="creator-stats-header">
                <img src={analyticsIcon} alt="Bumpups Logo" className="creatorpage-analytics-logo" />
                <h2 className="stats-title">Profile analytics</h2>
            </div>

            <h2 className="stats-summary-title">Summary</h2>
            
            <div className="stats-grid">
              
                <h3 className="stats-header">Videos Bumped</h3>
                <p className="stats-value"><b>{videosProcessed}</b></p>
                {/* <h3 className="stats-header">Words Processed</h3>
                <p className="stats-value">{videosNotProcessed}</p> */}
            </div>
             </div>

            <div className="creatorlogged-settings-wrapper">
          <div className="creator-settings-box">
            <Link to="/settings/creator" className="creatorpage-settings-button">
              <img src={settingsIcon} alt="Settings" className="creator-settings-icon" />
            </Link>
          </div>

          <div className="autocomment-settings-box">
            <button
              className={`autocomment-settings-button ${isToggledcomment ? 'autocomment-settings-button-green' : 'autocomment-settings-button-red'}`}
              onClick={handleCommentToggleChange}
              data-hover-text={isToggledcomment ? 'Auto-Comment Enabled' : 'Auto-Comment Disabled'}
            >
              <img src={commentIcon} alt="Comment Settings" className="autocomment-settings-icon" />
            </button>
          </div>

        </div>
        </div>
                
  </div>
      );
};

export default CreatorInformation;
