import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './Dashboard.css';

//FIREBASE
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from '../firebase';

// COMPONENTS
import CreatorAppNotification from '../components/creatorNotification'; // Adjust the path as needed
import AppTransition from '../components/AppTransition';
import { registerListener } from '../components/ListenerManager';

//IMPORT UI
import Sidebar from '../myDashboard/Sidebar';
import CloseSidebar from '../myDashboard/CloseSidebar';
import ThumbnailLayout from './ThumbnailLayout';
import CreatorInformation from './CreatorInformation';
import CreatorCalendar from './CreatorCalendar';
import InsufficientTimePopUp from '../components/miscPages/InsufficientTimePopUp'; // Adjust the path as needed
import ProcessingPage from '../unAuthPages/ProcessingPage';

//IMAGES
import bumpupsLogo from '../assets/images/bumpups-dark-small.jpg';




function Dashboard({ isSidebarOpen, toggleSidebar }) {

  const [searchvideo, setSearchVideo] = useState([]);

 // Use ISO string format and slice to get "YYYY-MM" for initial state
 const initialDate = sessionStorage.getItem('ISOselectedDate') || new Date().toISOString().slice(0, 7);
 const [selectedDate, setSelectedDate] = useState(initialDate);



  // const [isConnected, setIsConnected] = useState(false);  // State to hold connection status
  // const [isLoading, setIsLoading] = useState(true); // Loading state
  const [insufficientTime, setInsufficientTime] = useState(false);


  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS
  // FUNCTIONS LOGIC BEGINS


  useEffect(() => {
    // console.log('useEffect triggered');
    // console.log('Current selectedDate state:[DASHBOARD]', selectedDate);

    // Check if 'selectedDate' is already stored in sessionStorage
    const storedDate = sessionStorage.getItem('ISOselectedDate');
    if (!storedDate) {
      // If not, use the current "YYYY-MM" as the selectedDate
      const currentYearMonth = new Date().toISOString().slice(0, 7);
      setSelectedDate(currentYearMonth); // Update state
      sessionStorage.setItem('ISOselectedDate', currentYearMonth); // Update sessionStorage
      // console.log('Updated selectedDate to:', currentYearMonth);
    }
  }, [selectedDate]);




// Sets up a listener for a specific user's subscription data and handles cleanup
useEffect(()=>{
  const user=auth.currentUser;
  const userDocRef=doc(db,'users',user.uid);
  const insufficientTimeRef=doc(userDocRef,'subscriptionData','noTimeAvailable');

  const unsubscribe=onSnapshot(insufficientTimeRef,(docSnap)=>{
    if(docSnap.exists()){
      setInsufficientTime(docSnap.data().insufficientTime);
    }
  });

  registerListener(unsubscribe);

  return ()=>{
    unsubscribe(); // Cleanup
    // console.log("Successfully unsubscribed from insufficientTime listener");
  };
},[]);

  

  // USER CONNECTED VARs
  const [accessToken, setAccessToken] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const [progress, setProgress] = useState(0);


// Fetches user's YouTube access credentials from Firestore or validates existing session
useEffect(()=>{
  const fetchData=async()=>{

    setIsLoading(true);
    // setProgress(20);

    if(accessToken){
      // setIsConnected(true);
      // setProgress(50);

    }else{
      const user=auth.currentUser;
      const userId=user ? user.uid : null;

      if(userId){
        const userDoc=doc(db,"users",userId,'contentAccess','youtubeAccess');
        const docSnap=await getDoc(userDoc);

        if(docSnap.exists()){
          const fetchedChannelId=docSnap.data().channelId || null;
          setChannelId(fetchedChannelId);
          const fetchedAccessToken=docSnap.data().accessToken || null;
          setAccessToken(fetchedAccessToken);

          if(fetchedAccessToken){
            // setProgress(80);
            // setIsConnected(true);
            // console.log('AccessToken set in session storage and user marked as connected.');
          }
        }else{
          setChannelId(null);
        }
      }
    }
    // setProgress(100);
    // setIsLoading(false);
    setIsLoading(false); // End loading

     // Reset progress for next loading
    //  setTimeout(() => setProgress(0), 500);

  };



  fetchData();
},[accessToken,channelId]);

  



  return (
    <div className="creatordashboard">
     {/* <LoadingBar color="#aaa" progress={progress} /> */}
      {isSidebarOpen && <Sidebar />}
      {!isSidebarOpen && <CloseSidebar />}
      <AppTransition duration={1.0}>
      {isLoading ? (
        <ProcessingPage />
      ) : (
        
      <div className="creator-dashboard-content">
          <Helmet>
            <title>Bumpups</title>
            <meta name="description" content="Manage your YouTube video timestamps and settings in your Bumpups dashboard." />
          </Helmet>
          <div>
          <CreatorAppNotification />

            <CreatorInformation accessToken={accessToken} setAccessToken={setAccessToken} channelId={channelId} setChannelId={setChannelId} />

            <CreatorCalendar 
                searchvideo={searchvideo} 
                setSearchVideo={setSearchVideo} 
                selectedDate={selectedDate} 
                setSelectedDate={setSelectedDate} 
                accessToken={accessToken} 
                setAccessToken={setAccessToken} 
                channelId={channelId} 
                setChannelId={setChannelId} 
              />

            <ThumbnailLayout
                searchvideo={searchvideo}
                setSearchVideo={setSearchVideo}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                accessToken={accessToken}
                setAccessToken={setAccessToken}
                channelId={channelId}
                setChannelId={setChannelId}
              />


          </div>
          {insufficientTime && <InsufficientTimePopUp />}
        </div>
          )}
      </AppTransition>
    </div>
  );
  
}

export default Dashboard;