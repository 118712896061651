import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './bumpupDashboard.css';

// FIREBASE
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, auth, storage } from '../../firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

// FUNCTIONS
import { uploadBump } from '../uploadBump';
import AppTransition from '../../components/AppTransition';


// IMPORT UI
import ListViewBumpUp from './listviewBumpUp';
import Sidebar from '../../myDashboard/Sidebar';
import CloseSidebar from '../../myDashboard/CloseSidebar';
import ConnectedAccounts from './ConnectedAccounts';
import InsufficientTimePopUp from '../../components/miscPages/InsufficientTimePopUp'; // Adjust the path as needed


// IMAGES
import LoadingThumbnail from '../../assets/images/thumbnailPlacehlder.jpg';
import placeholderImage from '../../assets/images/placeholder-load.jpg';
import youTubeIcon from '../../assets/images/v2-creator.png';
import uploadLogo from '../../assets/images/upload-v1.png';
import utubeLogoConnected from '../../assets/images/redYouTube-v1.png';
import processBumpupslogo from '../../assets/gifmedia/bumpups-2.gif';

function BumpupDashboard({ isSidebarOpen, toggleSidebar }) {

    const [profileImage, setProfileImage] = useState(placeholderImage);
    const [accessToken, setAccessToken] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            const user = auth.currentUser;
            if (!user) return;

            const userId = user.uid;
            const profileDocRef = doc(db, 'users', userId, 'userSettings', 'profile');
            const accessDocRef = doc(db, "users", userId, 'contentAccess', 'youtubeAccess');

            try {
                const [profileSnapshot, accessSnapshot] = await Promise.all([
                    getDoc(profileDocRef),
                    getDoc(accessDocRef)
                ]);

                if (profileSnapshot.exists()) {
                    const profileData = profileSnapshot.data();
                    const profileImage = profileData.profileImage || placeholderImage;
                    setProfileImage(profileImage);
                    sessionStorage.setItem(`profileImage_${user.uid}`, profileImage);
                }

                if (accessSnapshot.exists()) {
                    const fetchedAccessToken = accessSnapshot.data().accessToken || null;
                    setAccessToken(fetchedAccessToken);
                }

                onSnapshot(profileDocRef, (doc) => {
                    if (doc.exists()) {
                        const profileImage = doc.data().profileImage || placeholderImage;
                        setProfileImage(profileImage);
                        sessionStorage.setItem(`profileImage_${user.uid}`, profileImage);
                    }
                });

                onSnapshot(accessDocRef, (doc) => {
                    if (doc.exists()) {
                        const fetchedAccessToken = doc.data().accessToken || null;
                        setAccessToken(fetchedAccessToken);
                    }
                });

            } catch (error) {
                // console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="bumps-uploaddashboard">
            {isSidebarOpen && <Sidebar />}
            {!isSidebarOpen && <CloseSidebar />}
            <AppTransition>
                <div className="bumps-dashboard-main">
                    <div className="bumps-dashboard-content-wrapper">
                        <div className="bumps-dashboard-contentgeneral">
                            <ConnectedAccounts profileImage={profileImage} accessToken={accessToken} />
                            <UploadSection profileImage={profileImage} accessToken={accessToken} />
                            </div>
                        <ListViewBumpUp accessToken={accessToken} />
                    </div>
                </div>
            </AppTransition>
        </div>
    );


}

const UploadSection = ({ profileImage, accessToken }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [showJustUploadedPopup, setShowJustUploadedPopup] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [videoId, setVideoId] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [timeBankBump, setTimeBankBump] = useState(0); // Added state for timeBankBump
    const [showInsufficientTimePopup, setShowInsufficientTimePopup] = useState(false); // State to control InsufficientTimePopUp
    const fileInputRef = useRef(null);
    const uploadTaskRef = useRef(null);
    const storagePathRef = useRef('');

    const MAX_FILE_SIZE_GB = 31;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_GB * 1024 * 1024 * 1024;

    useEffect(() => {
        // Assuming user and timeWardenRef are available from previous logic
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const timeWardenRef = doc(userDocRef, "subscriptionData", "timeWarden");

            // Listen for updates to timeBankBump
            const unsubscribeTimeBank = onSnapshot(timeWardenRef, (doc) => {
                if (doc.exists()) {
                    setTimeBankBump(doc.data().timeBankBump); // Set the timeBankBump state
                }
            });

            return () => {
                unsubscribeTimeBank();
            };
        }
    }, []);
      
    const [videoDurationSec, setVideoDurationSec] = useState(null);
    
    const handleUpload = useCallback(async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Check if the file size exceeds the maximum allowed size
        if (file.size > MAX_FILE_SIZE_BYTES) {
            alert(`The selected video file exceeds the maximum size of ${MAX_FILE_SIZE_GB} GB. Please select a smaller file.`);
            return;
        }

        // Calculate video duration
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';

        videoElement.onloadedmetadata = function() {
            window.URL.revokeObjectURL(videoElement.src);
            const duration = videoElement.duration; // duration is in seconds
            
            // Save the duration in state
            setVideoDurationSec(duration);
        
            // Convert timeBankBump from minutes to seconds
            const timeBankBumpInSeconds = timeBankBump * 60;
        
            // Log video duration and timeBankBump for debugging purposes
            // console.log(`Video duration: ${duration} seconds, TimeBankBump: ${timeBankBumpInSeconds} seconds`);
        
            // Check if the duration exceeds the timeBankBump
            if (duration > timeBankBumpInSeconds) {
                setShowInsufficientTimePopup(true); // Show the insufficient time popup
                return;
            } else {
                setSelectedFile(file);
                setShowJustUploadedPopup(true); // Show the initial popup
        
                const user = auth.currentUser;
                uploadBump(file, user, setUploadProgress, setVideoId, setShowPopup, (url) => {
                    // console.log('Thumbnail URL updated:', url);
                }, storagePathRef, uploadTaskRef, thumbnailUrl); // Pass thumbnailUrl here
        
                setShowJustUploadedPopup(false); // Hide the initial popup once upload starts
        
                // Reset the input field
                event.target.value = null;
            }
        };
        
        videoElement.src = URL.createObjectURL(file);
    }, [thumbnailUrl, MAX_FILE_SIZE_BYTES, timeBankBump]);



    useEffect(() => {
        const dropArea = document.querySelector('.bumps-file-drop-area');
    
        const handleDragOver = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) return; // Prevent actions if timeBankBump is 0, null, or undefined
    
            const files = event.dataTransfer.items;
            if (files.length > 0 && files[0].kind === 'file' && files[0].type.startsWith('video/')) {
                dropArea.classList.add('drag-over');
            }
        };
    
        const handleDragLeave = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) return; // Prevent actions if timeBankBump is 0, null, or undefined
    
            dropArea.classList.remove('drag-over');
        };
    
        const handleDrop = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) return; // Prevent actions if timeBankBump is 0, null, or undefined
    
            dropArea.classList.remove('drag-over');
            const file = event.dataTransfer.files[0];
            if (file) {
                handleUpload({ target: { files: [file] } });
            }
        };
    
        dropArea.addEventListener('dragover', handleDragOver);
        dropArea.addEventListener('dragleave', handleDragLeave);
        dropArea.addEventListener('drop', handleDrop);
    
        return () => {
            dropArea.removeEventListener('dragover', handleDragOver);
            dropArea.removeEventListener('dragleave', handleDragLeave);
            dropArea.removeEventListener('drop', handleDrop);
        };
    }, [timeBankBump, handleUpload]); // Add timeBankBump to dependencies
    

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="bumps-upload-section">
            <div className={`bumps-upload-area ${(timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) ? 'disabled' : ''}`}>
                <label htmlFor="fileInput" id="fileInputLabel" className="bumps-file-input-label">
                    <div className="bumps-file-drop-area">
                        <input 
                            type="file" 
                            id="fileInput" 
                            ref={fileInputRef}
                            accept="video/mp4,video/mov,video/webm,video/quicktime" 
                            onChange={handleUpload} 
                            hidden 
                            disabled={timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined}
                        />
                        <div className="upload-circle">
                            <img src={uploadLogo} alt="Upload Logo" className="upload-logo" />
                        </div>
                        {(timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) ? (
                            <>

                                <p className="upload-instruction">You need Bump Plan credits to upload videos</p>
                                <p className="upload-subtext">Please add Bump credits to enable uploads.</p>

                            </>
                        ) : (
                            <>
                                <p className="upload-instruction">Drag and drop video files to upload</p>
                                <p className="upload-subtext">Your videos will be private until you publish them.</p>
                            </>
                        )}
                        {(timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) ? (
                            <Link 
                                to="/plans" 
                                className="select-files-button"
                                style={{ textDecoration: 'none' }} // Ensure button is not underlined
                            >
                                Manage Plans
                            </Link>
                        ) : (
                            <button className="select-files-button" onClick={triggerFileInput}>
                                Select files
                            </button>
                        )}
                    </div>
                </label>
            </div>


            {showJustUploadedPopup && <JustUploadedPopup />}
            {showPopup && <UploadPopup 
                progress={uploadProgress} 
                file={selectedFile} 
                onClose={() => setShowPopup(false)} 
                uploadTaskRef={uploadTaskRef} 
                storagePathRef={storagePathRef} 
                videoId={videoId} 
                setThumbnailUrl={setThumbnailUrl} 
                profileImage={profileImage} 
                accessToken={accessToken}
                videoDurationSec={videoDurationSec} 
                timeBankBump={timeBankBump} 

                />}


            {showInsufficientTimePopup && <InsufficientTimePopUp />} 
        </div>
    );
};





function UploadPopup({ progress, file, onClose, uploadTaskRef, storagePathRef, videoId, setThumbnailUrl, profileImage, accessToken, videoDurationSec }) {
    const [videoUrl, setVideoUrl] = useState('');
    const [videoDurationExists, setVideoDurationExists] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [thumbnailUrl, setLocalThumbnailUrl] = useState('');
    const [uploadError, setUploadError] = useState(false);
    const user = auth.currentUser;
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);
    const [displayedProgress, setDisplayedProgress] = useState(progress);
    const [uploadStatusText, setUploadStatusText] = useState(`Uploading (${progress}%)`);


    useEffect(() => {
        const url = URL.createObjectURL(file);
        setVideoUrl(url);

        return () => URL.revokeObjectURL(url);
    }, [file]);

    useEffect(() => {
        if (progress < 98) {
            setDisplayedProgress(progress);
            setUploadStatusText(`Uploading (${progress}%)`);
        } else if (progress >= 98 && progress < 100) {
            setDisplayedProgress(98);
            setUploadStatusText(`Uploading (98%)`);
        } else if (progress === 100) {
            setDisplayedProgress(100);
            setUploadStatusText('Bumping...');
        }
    }, [progress]);



    const [isOutofTime, setIsOutofTime] = useState(false);
    useEffect(() => {
        const user = auth.currentUser;
    
        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const timeWardenRef = doc(userDocRef, "subscriptionData", "timeWarden");
    
            // Listen for updates to timeBankBump
            const unsubscribeTimeBank = onSnapshot(timeWardenRef, (doc) => {
                if (doc.exists()) {
                    const timeBankBumpInMinutes = doc.data().timeBankBump;
    
                    // Convert timeBankBump to seconds
                    const timeBankBumpInSeconds = timeBankBumpInMinutes * 60;
    
                    // Check if videoDurationSec is more than timeBankBumpInSeconds and progress is under 100
                    if (videoDurationSec > timeBankBumpInSeconds && progress < 100) {
                        setIsOutofTime(true);
    
                        // Cancel the upload if out of time and progress is under 100
                        if (uploadTaskRef.current) {
                            uploadTaskRef.current.cancel(); // Cancel the video upload task
                            // console.log("Upload canceled due to insufficient credits.");
                        }
                        } else {
                        setIsOutofTime(false);
                    }
                }
            });
    
            return () => {
                unsubscribeTimeBank();
            };
        }
    }, [videoDurationSec, progress, uploadTaskRef, onClose]);
    
    


    useEffect(() => {
        if (user && videoId) {
            const videoDocRef = doc(db, "users", user.uid, "bumpContent", `bump-${videoId}`);
            
            const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    const durationExists = 'video_duration_in_seconds' in data;
                    setVideoDurationExists(durationExists);
                    // console.log(`Video duration exists: ${durationExists}`);
                    if (durationExists) {
                        setUploadStatusText('Upload Complete');
                    }
                } else {
                    // console.log("No document found for this video.");
                }
            }, (error) => {
                // console.error("Error fetching video duration snapshot:", error);
            });

            return () => unsubscribe();
        }
    }, [user, videoId]);

    const handleCancelUpload = () => {
    
        if (progress === 100) {
            // console.log("Upload complete. Closing the dialog.");
            const localStorageKey = `videoCache_${user.uid}_BumpedVideos`;
            localStorage.removeItem(localStorageKey);
            localStorage.removeItem(`recentBump_${user.uid}`);
            onClose();
    
            // Reload the page
            window.location.reload();
        } else {
            // console.log("Upload not complete. Showing confirmation dialog.");
            setShowConfirmation(true);
        }
    };
    

    const [isDiscarding, setIsDiscarding] = useState(false);
    const handleConfirmClose = async () => {
        setIsDiscarding(true);

        if (progress < 100) {
            if (uploadTaskRef.current) {
                uploadTaskRef.current.cancel(); // Cancel the video upload task
            }
        }

        if (storagePathRef.current) {
            const videoRef = storagePathRef.current;
            try {
                await deleteObject(videoRef);
                // console.log('Video deleted successfully.');
            } catch (error) {
                // console.error('Error deleting video:', error);
            }
        }

        setIsDiscarding(false);
        setShowConfirmation(false);
        onClose();
    };

    const handleCancelClose = () => {
        setShowConfirmation(false);
    };

    const handleThumbnailUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/png', 'image/jpeg'];
            const maxSize = 1 * 1024 * 1024; // 1 MB

            if (validTypes.includes(file.type) && file.size <= maxSize) {
                const img = new Image();
                img.src = URL.createObjectURL(file);

                img.onload = () => {
                    const { width, height } = img;

                    const aspectRatio = width / height;
                    const isValidAspectRatio = aspectRatio === 16 / 9;

                    const isValidDimensions =
                        (width === 1280 && height === 720) ||
                        (width >= 640 && height >= 360 && isValidAspectRatio);

                    if (isValidDimensions) {
                        setLocalThumbnailUrl(LoadingThumbnail);
                        setIsUploadingThumbnail(true);

                        const thumbnailstorageRef = ref(storage, `bumpThumbnails/${user.uid}/${videoId}`);
                        const uploadTask = uploadBytesResumable(thumbnailstorageRef, file);

                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                // console.log('Upload is ' + progress + '% done');
                            },
                            (error) => {
                                // console.error('Error uploading thumbnail:', error);
                                setIsUploadingThumbnail(false);
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    // console.log('Thumbnail available at', downloadURL);
                                    setLocalThumbnailUrl(downloadURL);
                                    setThumbnailUrl(downloadURL);
                                    setIsUploadingThumbnail(false);
                                });
                            }
                        );
                    } else {
                        alert('Please upload a thumbnail with dimensions of 1280x720 pixels or a minimum width of 640 pixels and a 16:9 aspect ratio.');
                    }
                };
            } else {
                alert('Please upload a valid PNG or JPEG file under 1 MB.');
            }
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleViewVideo = () => {
        const localStorageKey = `videoCache_${user.uid}_BumpedVideos`;
        localStorage.removeItem(localStorageKey);
        localStorage.removeItem(`recentBump_${user.uid}`);

        navigate(`/bumper/${videoId}`);
    };

    useEffect(() => {
        const handleError = (error) => {
            // console.error('Upload error:', error);
            setUploadError(true);
        };

        if (uploadTaskRef.current) {
            uploadTaskRef.current.on('state_changed', null, handleError);
        }
    }, [uploadTaskRef]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (progress < 100) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [progress]);


    // Convert video duration in seconds to minutes
        let videoDurationMin = Math.floor(videoDurationSec / 60);
        if (videoDurationSec > 0 && videoDurationSec < 60) {
            videoDurationMin = 1;
        }


    return (
        <div className="bumps-upload-popup">
            <div className="bumps-upload-popup-content">
                <div className="bumps-upload-header">
                    <img src={profileImage} alt="User Profile" className="bumps-prevprofile-image" />
                    <button onClick={handleCancelUpload} className="bumps-upload-close-button">X</button>
                </div>
                <div className="bumps-upload-content-wrapper">
                    <div className="bumps-video-wrapper" style={{ position: 'relative' }}>
                        <video src={videoUrl} controls className="bumps-upload-video" />
                    </div>
                    <div className="bumps-upload-info-wrapper">
                        <div className="bumps-upload-info">
                            <p className="bumps-upload-message">
                                Keep your browser tab open to avoid interruptions. You may cancel anytime but if your video reaches 100% uploaded, your plan credits will be deducted. <b>[Credit Cost: {videoDurationMin}m]</b>
                            </p>
                            <a href="https://intercom.help/bumpups/en/articles/9742215-understanding-the-video-upload-process" className="bumps-learn-more-link" target="_blank" rel="noopener noreferrer">Learn more</a>
                        </div>
                    </div>
                    <div className={`bumps-upload-status-wrapper ${uploadError || isOutofTime ? 'error' : ''}`}>
                            <div className="bumps-upload-status">
                                <p className="bumps-upload-progress">
                                    <strong>
                                        {file.name}: {isOutofTime 
                                            ? 'You are out of credits' 
                                            : (uploadError ? 'Error Uploading -- Retry' : uploadStatusText)
                                        }
                                    </strong>
                                </p>
                            </div>
                        </div>
                </div>
                <div className="bumps-divider" />
                <div className="bumps-upload-buttons">
                    {accessToken ? (
                        <img src={utubeLogoConnected} alt="YouTube Icon" className="utubedistribution-icon-active" />
                    ) : (
                        <img src={youTubeIcon} alt="YouTube Icon" className="utubedistribution-icon" />
                    )}
                    <div className="bumps-buttons-right">
                        {thumbnailUrl && (
                            <img
                                src={thumbnailUrl}
                                alt="Thumbnail"
                                className="bumps-thumbnail-small"
                            />
                        )}
                        <input type="file" accept="image/png, image/jpeg" onChange={handleThumbnailUpload} ref={fileInputRef} style={{ display: 'none' }} />
                        <button
                                className={`bumps-upload-thumbnail-button ${progress >= 90 ? 'disabled' : ''}`}
                                onClick={triggerFileInput}
                                disabled={progress >= 90 || isOutofTime}
                                style={{ opacity: (progress >= 90 || isOutofTime) ? 0.3 : 1 }}
                                >
                                {isUploadingThumbnail ? 'Loading...' : 'Upload Thumbnail'}
                            </button>
                        <button
                            className={`bumps-bump-button ${displayedProgress < 100 || !videoDurationExists ? 'disabled' : ''}`}
                            disabled={displayedProgress < 100 || !videoDurationExists}
                            onClick={handleViewVideo}
                        >
                            Bump
                        </button>
                    </div>
                </div>
            </div>
            {showConfirmation && <CancelUploadPopup onConfirm={handleConfirmClose} onCancel={handleCancelClose} isDiscarding={isDiscarding} />}
        </div>
    );


}





function JustUploadedPopup() {
    return (
        <div className="just-uploaded-popup">
            <div className="just-uploaded-popup-content">
                <div className="uploading-wrapper">
                    <img src={processBumpupslogo} alt="Uploading" className="uploading-logo" />
                    <p className="uploading-text">Loading...</p>
                </div>
            </div>
        </div>
    );
}

function CancelUploadPopup({ onConfirm, onCancel, isDiscarding }) {
    return (
        <div className="cancel-upload-popup" onClick={onCancel}>
            <div className="cancel-upload-popup-content" onClick={(e) => e.stopPropagation()}>
                {!isDiscarding && <h2 className="cancel-upload-title">Hold that thought</h2>}
                {isDiscarding ? (
                    <div className="loading-wrapper">
                        <img src={processBumpupslogo} alt="Loading" className="loading-logo" />
                        <span className="loading-text">Deleting...</span>
                    </div>
                ) : (
                    <p className="cancel-upload-message">
                        We are still uploading your video. Are you sure you want to discard your post? Your draft will be lost.
                    </p>
                )}
                {!isDiscarding && (
                    <div className="cancel-upload-popup-buttons">
                        <button onClick={onCancel} className="cancel-button">Continue</button>
                        <button onClick={onConfirm} className="confirm-button">Discard</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BumpupDashboard;
