import React, { useState, useEffect } from 'react';
import './AppTransition.css'; // Import the CSS file for AppTransition

const AppTransition = ({ children, duration = 0.4 }) => { // Set a default duration
  const [fadeIn, setFadeIn] = useState(false); // Initialize as false to start with a fade-in effect

  useEffect(() => {
    // After the component mounts, trigger a fade-in effect after a short delay
    setTimeout(() => {
      setFadeIn(true);
    }, 100);
  }, []);

  return (
    <div className="app-transition-wrapper"> {/* Add a CSS class */}
      {React.Children.map(children, (child) => {
        // Check if the child is a Sidebar component
        if (React.isValidElement(child) && child.type.name === 'Sidebar') {
          // If it's a Sidebar component, render it without opacity transition
          return React.cloneElement(child, { disableTransition: true });
        } else {
          // If it's not a Sidebar component, apply opacity transition
          return (
            <div style={{ opacity: fadeIn ? 1 : 0, transition: `opacity ${duration}s` }}>
              {child}
            </div>
          );
        }
      })}
    </div>
  );
};

export default AppTransition;
