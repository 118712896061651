import React from 'react';
import '../components/miscPages/SellingPoints.css';

//IMAGES
import brandIcon from '../assets/images/darkbrand-v3.png';
import catalogIcon from '../assets/images/livesteam-v1.png';
import commentIcon from '../assets/images/darkcomment-v3.png';


const PreviewPoints = () => {



    return (
        <div className="unauth-preview-points">
          <div className="unauth-preview-point-item">
            <img src={brandIcon} alt="Point Logo" className="unauth-point-logo" />
            <h3 className="unauth-selling-point-title">Your Brand</h3>
          </div>
          <div className="unauth-preview-point-item">
            <img src={commentIcon} alt="Point Logo" className="unauth-point-logo" />
            <h3 className="unauth-selling-point-title">Auto-Comment</h3>
          </div>
          <div className="unauth-preview-point-item">
            <img src={catalogIcon} alt="Point Logo" className="unauth-point-logo" />
            <h3 className="unauth-selling-point-title">Your Catalog</h3>
          </div>
        </div>
      );
      
  };
  
  export default PreviewPoints;