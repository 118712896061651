import React from 'react';
import './SellingPoints.css';

//IMAGES
import stampLogo from '../../assets/images/timestamp-v3.png';
import podcastLogo from '../../assets/images/podcast-v1.png';
import brandBrush from '../../assets/images/darkbrand-v3.png';
import livestreamLogo from '../../assets/images/livesteam-v1.png';
import videotagIcon from '../../assets/images/hashtag-v1.png';
import descripIcon from '../../assets/images/descrip-v1.png';
import takeawaysIcon from '../../assets/images/takeaways-v1.png';
import creditIcon from '../../assets/images/credit-v1.png';


const SellingPoints = () => {

    

    
    return (


            <div className="selling-points">
                <div className="selling-point-item">
                    <img src={stampLogo} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Auto-Timestamps</h3>
                    <p className="selling-point-description">Effortlessly create precise timestamps for all uploads.</p>
                </div>
                <div className="selling-point-item">
                    <img src={creditIcon} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Hassle-Free Start</h3>
                    <p className="selling-point-description">Get started for free, no credit card required.</p>
                </div>
                <div className="selling-point-item">
                    <img src={podcastLogo} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Extended Video Processing</h3>
                    <p className="selling-point-description">Seamlessly process videos up to 3.5 hours long.</p>
                </div>
                <div className="selling-point-item">
                    <img src={livestreamLogo} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Live Stream Highlights</h3>
                    <p className="selling-point-description">Easily pinpoint and clip key moments.</p>
                </div>
                <div className="selling-point-item">
                    <img src={brandBrush} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Tailored Branding</h3>
                    <p className="selling-point-description">Customize processing to align with your brand.</p>
                </div>
                <div className="selling-point-item">
                    <img src={videotagIcon} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Auto-Tags</h3>
                    <p className="selling-point-description">Automatically generate relevant tags for your videos.</p>
                </div>
                <div className="selling-point-item">
                    <img src={takeawaysIcon} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Auto-Takeaways</h3>
                    <p className="selling-point-description">Highlight the key points of your videos effortlessly.</p>
                </div>
                <div className="selling-point-item">
                    <img src={descripIcon} alt="Point Logo" className="point-logo" />
                    <h3 className="selling-point-title">Auto-Descriptions</h3>
                    <p className="selling-point-description">Generate engaging descriptions automatically.</p>
                </div>





            </div>


    );

};

export default SellingPoints;
