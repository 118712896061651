import React from 'react';
import './Aboutus.css'; // Make sure this path is correct

//IMPORT UI
import Footer from './Footer';

//IMAGES
import aboutImage from '../assets/images/aboutus.jpg';
import noncreatorIcon from '../assets/images/v2-noncreator.png';
import valueIcon from '../assets/images/valueicon-v2.png';
import futureTeam from '../assets/images/teams-v2.png';



const Aboutus = () => {
  return (
    <div className="aboutus-container">
      <div className="aboutus-header">
        <h1>Crafted by Creators, for Creators</h1>
      </div>
      <div className="aboutus-content">
      <div className="pill">
          <img src={noncreatorIcon} alt="Time Logo" className="pill-icon" />
          <span className="pill-text">Origin</span>
        </div>
        <section className="aboutus-section">
          <h2>New Age of Content Creation</h2>
          <p>
            As the creator of Tubestamp, I, Corbin Brown, began this journey out of my own necessity <b>as a content creator</b> based in San Francisco. The inception of Tubestamp was sparked by the realization of how crucial, yet cumbersome, it was to manually add timestamps to videos.
          </p>
          <p>
          This realization led me to envision a platform where timestamps could be <b>generated automatically</b>, without any extra effort from the user.
          </p>
        </section>
        <div className="pill">
          <img src={valueIcon} alt="Time Logo" className="pill-icon" />
          <span className="pill-text">Value</span>
        </div>
        <section className="aboutus-section">
          <h2>AI For Video Enhancement</h2>
          <img src={aboutImage} alt="Tubestamp Thumbnail" className="aboutus-thumbnail" />
          <p>
            My experiences in content creation highlighted a <b>gap in the market for tools</b> that genuinely cater to the needs of creators like myself. This insight drove me to expand Tubestamp's capabilities beyond just automatic timestamping.
          </p>
          <p>
             My mission is to continually enhance the platform, <b>adding more value at each step of the video processing journey</b>, starting with a feature that allows viewers to navigate content more effortlessly.
          </p>
        </section>
        <div className="pill">
          <img src={futureTeam} alt="Time Logo" className="pill-icon" />
          <span className="pill-text">Team</span>
        </div>
        <section className="aboutus-section">
          <h2>The Future of Video Processing</h2>
          <p>
            Tubestamp is more than just a tool; it's an evolving AI software platform designed from the ground up with content creators in mind. As a creator myself, I understand the challenges and nuances of producing engaging content, and <b>I'm committed to making Tubestamp an indispensable asset for creators everywhere</b>.
          </p>
          <p>
            My vision for Tubestamp is expansive. I'm on a mission to <b>grow our team</b> by onboarding more software engineers who share a passion for creating innovative solutions that empower content creators. 
          </p>
          <p>
             Together, we're not just developing software; we're building a community and a platform that resonates with the <b>needs and aspirations of content creators globally</b>.
          </p>
          <p>
             <i>Support and funding from <b>Webcafe AI</b> - San Francisco</i>
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
